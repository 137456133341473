import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Form } from 'rsuite';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import closeIcon from 'assets/svg/btn-close.svg';

import { TextField } from 'components/core/fields/TextField';

import { showSuccessNotification } from 'components/core/Notification';
import { getPromotionCodeForCart } from 'lib/shared/promotionCode';
import { getCartTotals } from 'lib/shared/cart';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function DiscountBlock() {
  const dispatch = useDispatch();

  const [showDiscountInput, setShowDiscountInput] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cart = useAppSelector((state) => state.experience.alloyCart);

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  /**
   * Attempt to apply the promotion code to the treatment plan cart
   */
  const onApply = async () => {
    try {
      setIsLoading(true);

      const { subtotal } = getCartTotals(cart);

      const stripeProductIds = cart.products.flatMap((gcp) =>
        gcp.alloyProduct.parent.map((pf) => pf.stripeProductId)
      );

      const promotionCode = await getPromotionCodeForCart(promoCode, subtotal, stripeProductIds);

      dispatchUpdateCart({
        promotionCode,
      });

      showSuccessNotification('Promo code successfully applied');

      setPromoCode('');
      setShowDiscountInput(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Remove the promotion code from the treatment plan cart
   */
  const onRemove = () => {
    dispatchUpdateCart({
      promotionCode: {
        id: '',
        name: '',
        discountAmount: 0,
        isReferral: false,
      },
    });

    showSuccessNotification('Promo code successfully removed');

    setPromoCode('');
    setShowDiscountInput(false);
  };

  /**
   * If a promotion code exists, show that to the user
   */
  if (cart.promotionCode.id !== '') {
    return (
      <div className='ce-discount-block'>
        <div className='discount-content'>
          <button onClick={onRemove} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <p className='discount-text'>Active Discount: {cart.promotionCode.name}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='ce-discount-block'>
      {showDiscountInput ? (
        <div className='discount-content'>
          <button
            onClick={() => setShowDiscountInput(false)}
            className='discount-close-btn'
            disabled={isLoading}
          >
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <div className='dicount-input-wrapper'>
            <Form>
              <TextField
                name='code'
                placeholder='Discount code'
                classes='discount-input'
                value={promoCode}
                onChange={(value) => setPromoCode(value.toUpperCase())}
                disabled={isLoading}
              />

              <button
                className='discount-btn'
                onClick={onApply}
                disabled={isLoading || promoCode === ''}
              >
                Apply
              </button>
            </Form>
          </div>
        </div>
      ) : (
        <button className='discount-link' onClick={() => setShowDiscountInput(true)}>
          Have a Promo Code?
        </button>
      )}
    </div>
  );
}
