import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { footerTitles, footerItems, socialItems } from 'data/footer';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className='d-flex py-5 footer-bg'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='legitscript-logo'>
              <script async src='https://static.legitscript.com/seals/9870421.js' />
              <a
                href='https://www.legitscript.com/websites/?checker_keywords=myalloy.com'
                target='_blank'
                rel='noreferrer'
                title='Verify LegitScript Approval'
              >
                <img
                  src='https://static.legitscript.com/seals/9870421.png'
                  alt='LegitScript approved'
                  width='140'
                  height='100'
                />
              </a>
            </div>
            <div className='klaviyo-form-UPrrB3 pl-0 pr-0 pr-md-4'></div>
          </div>

          {footerItems.map((items, row) => (
            <div className='col-6 col-md-2 mb-5 mb-lg-0' key={row}>
              <h4 className='footer-subtitle'>{footerTitles[row]}</h4>
              <ul className='footer-links mt-2'>
                {items.map((item, col) => (
                  <li className='mb-1' key={`${row}-${col}`}>
                    <a
                      href={
                        item.directLink
                          ? item.link
                          : process.env.REACT_APP_MARKETING_URL + item.link
                      }
                      {...(item.directLink && { target: '_blank' })}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className='content-divider'></div>

        <div className='row'>
          <div className='col-12 col-md-8 text-center text-md-left'>
            <p className='font-weight-normal font-small footer-copyright'>
              © {year} Alloy, Inc. All rights reserved&nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/privacy-policy`}
                className='footer-copyright'
              >
                Privacy Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/privacy-policy#consumer-health-data-privacy-policy`}
                className='footer-copyright'
              >
                Consumer Health Data Privacy Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href={`${process.env.REACT_APP_MARKETING_URL}/terms-of-use`}
                className='footer-copyright'
              >
                Terms & Conditions
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                onClick={(e) => {
                  e.preventDefault();
                  // @ts-ignore
                  window.Osano.cm.showDrawer();
                }}
                className='footer-copyright'
              >
                Manage Cookie Preferences
              </a>
            </p>
          </div>

          <div className='col-12 col-md-4'>
            <ul className='d-flex float-right social'>
              {socialItems.map((item, row) => (
                <li key={row}>
                  <a href={item.link} target='_blank' className='btn btn-icon' rel='noreferrer'>
                    <span>
                      <FontAwesomeIcon icon={item.icon} size='lg' />
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
