import { xor } from 'lodash';
import { useEffect, useState } from 'react';

import checkedBox from 'assets/svg/core/icons/checked-box-icon.svg';
import uncheckedBox from 'assets/svg/core/icons/unchecked-box-icon.svg';
import checkedRadioIcon from 'assets/svg/core/icons/checked-radio-icon.svg';
import uncheckedRadioIcon from 'assets/svg/core/icons/unchecked-radio-icon.svg';
import { TextAreaField } from './TextAreaField';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import classNames from 'classnames';

export interface CheckboxOption {
  text: string;
  title?: string;
  id: string;
  parentOptionSlug?: string;
  followUp?: {
    title: string;
    slug: string;
    description: string;
  };
}

export type FollowUps = {
  [key: string]: string;
};

interface Props {
  selectedIds: string[];
  onChange: (ids: string[]) => void;
  followUps?: QuestionnaireAnswers;
  setFollowUps?: (followUps: QuestionnaireAnswers) => void;
  options: CheckboxOption[];
  isSingleSelect?: boolean;
}

/**
 * This ia checkbox group that can be used anywhere in the app. It serves the purpose of displaying checkboxes with text
 * and then handling the selectability of it behind the scenes with 'id' to return back an array of selected ids
 */
export const SelectFieldsGroup = ({
  selectedIds,
  onChange,
  followUps,
  setFollowUps,
  options,
  isSingleSelect = false,
}: Props) => {
  const [selected, setSelected] = useState(selectedIds);

  useEffect(() => {
    setSelected(selectedIds);
  }, [JSON.stringify(selectedIds)]);

  const onSelect = (option: CheckboxOption) => {
    // xor is a cool lodash method to add a value to an array if it doesn't exist in the array
    // or remove that id from the array if it does exist!

    const updatedSelectedIds = xor(selected, [option.id]);
    setSelected(updatedSelectedIds);

    onChange(updatedSelectedIds);
  };

  return (
    <div className='input-field-wrapper'>
      <div className='field-checkbox-group'>
        {options.map((option, index) => (
          <div className='field-checkbox-wrapper' key={index}>
            <div
              className={classNames('field-checkbox', selected.includes(option.id) && 'selected')}
              key={index}
              onClick={() => onSelect(option)}
            >
              {isSingleSelect ? (
                <img
                  src={selected.includes(option.id) ? checkedRadioIcon : uncheckedRadioIcon}
                  alt='selectable radio icon'
                  className='checkbox-select-icon'
                />
              ) : (
                <img
                  src={selected.includes(option.id) ? checkedBox : uncheckedBox}
                  alt='selectable checkbox icon'
                  className='checkbox-select-icon'
                />
              )}

              <div>
                {option.title && <p className='checkbox-title'>{option.title}</p>}
                <p className='checkbox-text'>{option.text}</p>
              </div>
            </div>

            {/* follow up questions inside a select field (only if exists) */}
            {followUps && option.followUp && selected.includes(option.id) && (
              <div className='field-checkbox-follow-up'>
                <TextAreaField
                  name={option.text}
                  label={option.followUp.title}
                  value={followUps[option.followUp.slug] as string}
                  rows={4}
                  placeholder={option.followUp.description}
                  onChange={(textAnswer) =>
                    setFollowUps &&
                    setFollowUps({ ...followUps, [option.followUp?.slug as string]: textAnswer })
                  }
                  data-private='lipsum'
                  data-sentry-mask
                  isFollowUp
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
