// lib/shared/url/query.ts

import { useNavigate, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Getter for query parameters, returning only string or null
  const getParam = (key: string): string | null => {
    return searchParams.get(key);
  };

  // Setter for query parameters
  const setParam = (key: string, value: string): void => {
    searchParams.set(key, value);

    // Update the URL with the new search parameters
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  // Setter for multiple query parameters
  const setParams = (params: Record<string, string>): void => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    // Update the URL with the new search parameters
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  // Navigate to a specific URL with query parameters
  const navigateWithParams = (path: string, params: Record<string, string> = {}) => {
    const urlSearchParams = new URLSearchParams();

    // Set each param in the URL search parameters
    for (const [key, value] of Object.entries(params)) {
      urlSearchParams.set(key, value);
    }

    // Navigate to the constructed URL
    navigate({
      pathname: path,
      search: urlSearchParams.toString(),
    });
  };

  return {
    getParam,
    setParam,
    setParams,
    navigateWithParams,
  };
};
