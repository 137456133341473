import { ApiOrder, useGetAllOrders } from 'client/dist/generated/alloy';
import Loader from 'components/core/Loader';
import DownloadInvoiceButton from 'components/shared/DownloadInvoiceButton';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useProductContext } from 'context/dashboard/subscriptions/product';
import { getTime } from 'date-fns';
import { useQueryParams } from 'context/shared/url/query';
import { useEffect, useState } from 'react';

export default function DownloadInvoiceContent() {
  const { reasonType, onCancel } = useCancelContext();
  const { product } = useProductContext();
  const { setParam } = useQueryParams();

  const [recentOrder, setRecentOrder] = useState<ApiOrder | null>(null);

  const { data: orders = [], isLoading: isLoadingOrders } = useGetAllOrders();

  useEffect(() => {
    // sorted orders with last one being most recent
    const sorted = orders
      .sort((a, b) => getTime(new Date(a.createdAt!)) - getTime(new Date(b.createdAt!)))
      .filter((o) => o.productFrequencies.some((pf) => pf.id === product.alloyProduct.parent[0].id))
      .filter((o) => !o.isConsult);

    setRecentOrder(sorted[sorted.length - 1]);
  }, [isLoadingOrders]);

  if (isLoadingOrders || !recentOrder) {
    return <Loader />;
  }

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          {reasonType === 'TOO_EXPENSIVE'
            ? 'Did you know there are ways to reduce the cost of your prescriptions?'
            : 'Before you go, maybe we can help!'}
        </h4>

        <p className='content-text'>
          With Alloy, you can download an invoice to submit to your insurance which can help with
          the cost of your treatments.
        </p>
      </div>

      <div className='btns-stack-block'>
        <DownloadInvoiceButton
          order={recentOrder}
          onDownloaded={() => setParam('outcome', 'invoice')}
        />

        <button className='primary-link' onClick={() => onCancel()}>
          I want to cancel
        </button>
      </div>
    </div>
  );
}
