import doctors from 'assets/images/checkout-experience/expectations/doctors.png';
import {
  doctorIcon,
  doctorLightIcon,
  medicineIcon,
  medicineLightIcon,
  ongoingIcon,
  ongoingLightIcon,
  truckIcon,
  truckLightIcon,
} from 'assets/svg/checkout-experience/expectations';

import { HowItWorksItem } from 'components/checkout-experience/expectations/HowItWorksBlock';
import { IconText } from 'models/components/shared/icon-text';

const baseHowItWorks: HowItWorksItem[] = [
  {
    title: 'Tell us about yourself',
    text: 'Fill out our quick assessment and tell us about your symptoms.',
  },
  {
    title: 'Connect with a doctor',
    text: 'Your doctor will review your assessment and answer your questions to ensure you get the products most effective for you.',
  },
  {
    title: 'Get treatments delivered',
    text: 'Your prescription medications will be delivered directly to your door.',
  },
];

export const howItWorks: HowItWorksItem[] = [
  {
    title: 'Fill out the intake form',
    text: 'Tell us about your symptoms',
  },
  {
    title: 'Message with your doctor',
    image: doctors,
    text: 'Your doctor will review your assessment and answer your questions to ensure you get the products most effective for you.',
  },
  ...baseHowItWorks.slice(2),
];

export const howItWorksSkin: HowItWorksItem[] = [
  {
    title: 'Tell us about yourself',
    text: 'Fill out our quick assessment and tell us about your symptoms.',
  },
  ...baseHowItWorks.slice(1, 2),
  {
    title: 'Get skincare delivered',
    text: 'Your prescription medications will be delivered directly to your door.',
  },
];

export const howItWorksSex: HowItWorksItem[] = baseHowItWorks;

export const mhtSteps: string[] = [
  'Fill out the intake',
  'Connect with a doctor',
  'Get your treatments delivered',
];

export const features: IconText[] = [
  {
    icon: doctorIcon,
    text: 'Menopause-specialized doctors',
  },
  {
    icon: ongoingIcon,
    text: 'Ongoing care and education',
  },
  {
    icon: medicineIcon,
    text: 'Medicine delivered to your door',
  },
  {
    icon: truckIcon,
    text: 'Free shipping • Cancel anytime',
  },
];

export const featuresWithLightIcons: IconText[] = features.map((item) => ({
  ...item,
  icon: {
    [doctorIcon]: doctorLightIcon,
    [ongoingIcon]: ongoingLightIcon,
    [medicineIcon]: medicineLightIcon,
    [truckIcon]: truckLightIcon,
  }[item.icon],
}));
