import {
  DeepProduct,
  PreSubmission,
  Submission,
  postSubmission,
  processRenewalComplete,
  runSuggestions,
  updatePartialSubmission,
} from 'client/dist/generated/alloy';
import { getRenewalHiddenResults } from 'lib/checkout-experience/intake/renewal';

import { Dispatch } from '@reduxjs/toolkit';

import { sendExceptionToSentry } from 'lib/tracking/sentry';

/**
 *
 * @param questionnaire questionnaire - from checkout experience flow
 * @param customer Customer - alloy customer from our db
 * @param submission Submission - from filling out questionnaire
 * @param products DeepProduct[] - expiring prescriptions deep products
 * @returns
 */
export const completeRenewal = (submission: PreSubmission, products: DeepProduct[]) => {
  return async (dispatch: Dispatch) => {
    try {
      const renewalProducts = products.map((pf) => pf.name).join(', ');

      // MARK: Create the inital response so we can get DQd products
      const submissionResponse = await postSubmission(submission);
      const submissionId = submissionResponse.id;

      const { disqualified } = await runSuggestions({ submissionId });

      // MARK: Combine the renewal products as well as pull in DQd products from our suggestions
      const dqProducts = disqualified.map((p) => p.product.name).join(', ');

      const results = getRenewalHiddenResults(renewalProducts, dqProducts);

      // MARK: Update our submission with the extra hidden answers for doc
      const updatedSubmission: Partial<Submission> = {
        id: submissionId,
        categories: ['renewal'],
        answers: results,
      };

      await updatePartialSubmission(updatedSubmission);

      // Complete the renewal process
      await processRenewalComplete({
        productFrequencyIds: products.map((pf) => pf.id),
      });
    } catch (error) {
      sendExceptionToSentry(error as Error);

      throw error;
    }
  };
};
