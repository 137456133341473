import { Elements } from '@stripe/react-stripe-js';
import {
  Customer,
  IntakeCategory,
  useGenerateSetupIntent,
  useGetCustomerReferralCreditBalance,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { bindActionCreators } from '@reduxjs/toolkit';

import { processCheckout } from 'actions/checkout-experience/checkout_actions';
import { buildCheckoutExperience } from 'actions/checkout-experience/flow_actions';

import CheckoutSection from 'components/shared/checkout/CheckoutSection';
import SinglePageProductPerksWrapper from 'components/shared/checkout/layout/SinglePageProductPerksWrapper';
import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';

import Layout from 'containers/Layout';

import { getCheckoutType, isConsultCart } from 'lib/checkout-experience/checkout/cart';
import { subscribeNewsletter } from 'lib/core/subscribeNewsletter';
import { retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { stripePromise } from 'lib/shared/payment';
import sendAbandonEvent from 'lib/tracking/sendAbandonEvent';
import { posthogCapture } from 'lib/tracking/posthog';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useAppSelector } from 'reducers/alloy_reducer';
import { getDeepProductsFromGroupedProducts } from 'lib/shared/product';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: In clean up move this to be location!
export type Address = Pick<
  Customer,
  | 'firstName'
  | 'lastName'
  | 'shippingAddressLineOne'
  | 'shippingAddressLineTwo'
  | 'city'
  | 'stateAbbr'
  | 'zip'
  | 'phoneNumber'
>;

export default function Checkout({ onBack }: Omit<ExperienceComponentModel, 'onNext'>) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const cart = useAppSelector((state) => state.experience.alloyCart);
  const customer = useAppSelector((state) => state.alloy.customer!!);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkoutStep, setCheckoutStep] = useState<'shipping' | 'payment'>('shipping');

  const { isLoading: isLoadingReferralCredits } = useGetCustomerReferralCreditBalance();
  const { isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { data: setupIntent, isLoading: isLoadingSetupIntent } = useGenerateSetupIntent();

  const isAnyLoading =
    isLoading || isLoadingReferralCredits || isLoadingPaymentMethods || isLoadingSetupIntent;

  const dispatchBuildCheckoutExperience = bindActionCreators(buildCheckoutExperience, dispatch);

  const dispatchProcessCheckout = bindActionCreators(processCheckout, dispatch);

  /**
   * Sometimes users end up in weird states where the cart products length = 0
   * When that happens we just need to refresh the CE flow and get the user to the appropriate page
   * / with the appropriate products in cart!
   */
  useEffect(() => {
    const checkCart = async () => {
      if (cart.products.length === 0) {
        const retrievedCategories = retrieveCategoriesFromUrl(location);

        const url = location.pathname;

        await dispatchBuildCheckoutExperience(url, retrievedCategories, navigate);
      }
    };

    checkCart();
  }, [cart]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const products = getDeepProductsFromGroupedProducts(cart.products);
    const categories = retrieveCategoriesFromUrl(location);

    sendAbandonEvent({
      event: 'CHECKOUT_SHOWN',
      categories,
      experience: 'checkout',
      products,
    });
  }, []);

  const onPlaceOrder = async () => {
    window.scrollTo(0, 0);

    try {
      setIsLoading(true);

      const categories = retrieveCategoriesFromUrl(location);

      const filteredIntakeCategories = categories.filter(
        (c) => c !== 'gut-health'
      ) as IntakeCategory[];

      const isConsult = isConsultCart(cart);
      const isOtcOnly = getCheckoutType(cart, filteredIntakeCategories) === 'OTC';

      await dispatchProcessCheckout(cart, categories, 'checkout');

      sendAbandonEvent({
        event: 'CHECKOUT_COMPLETED',
        categories,
        experience: 'checkout',
      });

      subscribeNewsletter(customer.email, 'opted_in');

      const searchParams = new URLSearchParams(location.search);

      if (isOtcOnly) {
        searchParams.append('otc-checkout', 'true');
      } else if (isConsult) {
        searchParams.append('consult-checkout', 'true');
      }

      posthogCapture('purchase', categories);

      navigate({
        pathname: '/order-confirmation',
        search: `?${searchParams.toString()}`,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const goBack = isAnyLoading
    ? undefined
    : checkoutStep === 'payment'
    ? () => setCheckoutStep('shipping')
    : onBack;

  const onNextStep = () => {
    if (checkoutStep === 'shipping') {
      setCheckoutStep('payment');
    }
  };

  return (
    <Layout title='Checkout - Alloy' desc='' noBars>
      <TopBannerWithProgress onBack={goBack} />
      {isAnyLoading || !setupIntent ? (
        <Loader />
      ) : (
        <Elements stripe={stripePromise} options={{ clientSecret: setupIntent.client_secret }}>
          <SinglePageProductPerksWrapper />
          <CheckoutSection
            onPlaceOrder={onPlaceOrder}
            checkoutStep={checkoutStep}
            onNextStep={onNextStep}
          />
        </Elements>
      )}
    </Layout>
  );
}
