import { Question as AlloyQuestion } from 'common/dist/models/questionnaire';
import { Question } from 'components/checkout-experience/questionnaire/Question';
import { showErrorNotification } from 'components/core/Notification';
import { getFilteredPages, getPages } from 'lib/shared/questionnaire/question';
import { validDob } from 'lib/shared/date';
import {
  getIncompleteSection,
  hasUnansweredRequired,
  validateDateQuestions,
} from 'lib/shared/questionnaire/validation';
import { useQueryParams } from 'context/shared/url/query';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
interface Props {
  questions: AlloyQuestion[];
  previousAnswers: QuestionnaireAnswers;
  onContinue: (answers: QuestionnaireAnswers) => void;
}

export default function QuestionnaireWrapper({
  questions = [],
  previousAnswers = {},
  onContinue,
}: Props) {
  const location = useLocation();
  const { getParam, setParam } = useQueryParams();

  const [answers, setAnswers] = useState<QuestionnaireAnswers>(previousAnswers);
  const [index, setIndex] = useState(0);

  const pages = getPages(questions, []);
  const filteredPages = getFilteredPages(pages, answers);

  useEffect(() => {
    const nextSection = getParam('section');

    if (!!nextSection) {
      const nextSectionIndex = getIncompleteSection(pages, answers, nextSection);

      setIndex(nextSectionIndex);
    } else {
      setIndex(0);
    }
  }, [location.search]);

  const handleUpdateAnswers = (updatedAnswers: QuestionnaireAnswers) => {
    filteredPages.flat().forEach(({ slug }) => {
      if (!updatedAnswers[slug]) {
        delete updatedAnswers[slug];
      }
    });

    setAnswers(updatedAnswers);
  };

  const onNext = () => {
    const questionsValidated = validateDateQuestions(filteredPages, answers, showErrorNotification);

    if (!questionsValidated) return;

    if (index === filteredPages.length - 1) {
      onContinue(answers);
    } else {
      const nextSection = pages[index + 1][0].groupedSection || pages[index + 1][0].slug;
      setParam('section', nextSection);

      setIndex(index + 1);
    }
  };

  return (
    <div className='drawer-questionnaire-wrapper'>
      <form className='questionaire-form-wrapper'>
        {filteredPages[index].map((question, index) => (
          <Question
            key={index}
            question={question}
            answers={answers}
            setAnswers={handleUpdateAnswers}
          />
        ))}
      </form>

      <button
        className='primary-button'
        onClick={onNext}
        disabled={hasUnansweredRequired(filteredPages[index], answers)}
      >
        Continue
      </button>
    </div>
  );
}
