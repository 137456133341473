import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useQueryParams } from 'context/shared/url/query';

export default function MovingContent() {
  const { navigateWithParams } = useQueryParams();

  const { onCancel } = useCancelContext();

  const onUpdateAddress = async () => navigateWithParams('/account/profile'); // TODO: think about params maybe later on profile

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>Did you know Alloy is available in all 50 states?</h4>
        <p className='content-text'>
          Your treatments can be sent to your new address! Just update your shipping address.
        </p>
      </div>

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onUpdateAddress}>
          Update my address
        </button>

        <button className='primary-link' onClick={() => onCancel()}>
          I want to cancel
        </button>
      </div>
    </div>
  );
}
