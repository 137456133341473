import { Question } from 'common/dist/models/questionnaire';
import { findIndex, includes, isEmpty, some } from 'lodash';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { validDob } from '../date';

/**
 * Finds the index of the next section with incomplete required questions, or a specified target section.
 *
 * @param questions Question[][]
 * @param answers QuestionnaireAnswers
 * @param section string
 * @returns The index of the next incomplete section or the specified section if complete.
 */
export const getIncompleteSection = (
  questions: Question[][],
  answers: QuestionnaireAnswers,
  section: string
): number => {
  return (
    findIndex(questions, (pageQuestions, pageIndex) => {
      // Determine if this page is the target section or has unanswered required questions
      const isCurrentSection = some(
        pageQuestions,
        (question) => (question.groupedSection || question.slug) === section
      );

      const hasUnansweredRequiredQuestions = some(pageQuestions, (question) => {
        if (!question.isRequired || !shouldShowQuestion(question, answers)) return false;

        const answer = answers[question.slug];

        return isEmpty(answer);
      });

      // Match the target section or identify if any required questions are unanswered
      return hasUnansweredRequiredQuestions || isCurrentSection;
    }) || 0
  );
};

/**
 * Checks if any required questions within a given page are unanswered.
 *
 * @param questions Question[]
 * @param answers QuestionnaireAnswers
 * @returns True if any required question on the page is unanswered; false otherwise.
 */
export const hasUnansweredRequired = (
  questions: Question[],
  answers: QuestionnaireAnswers
): boolean => {
  return questions.some(({ slug, isRequired, type }) => {
    const answer = answers[slug];
    return (
      isRequired && (!answer || answer.length === 0) && type !== 'Information' && type !== 'Hidden'
    );
  });
};

/**
 * Determines if all required questions across all sections/pages are answered.
 *
 * @param questions Question[][]
 * @param answers QuestionnaireAnswers
 * @returns True if all required questions are answered; false if any are unanswered.
 */
export const areAllQuestionsAnswered = (
  questions: Question[][],
  answers: QuestionnaireAnswers
): boolean => {
  return !questions.some((pageQuestions) => hasUnansweredRequired(pageQuestions, answers));
};

/**
 * Determines if a question should be displayed based on its conditional logic.
 *
 * @param question Question
 * @param answers QuestionnaireAnswers
 * @returns True if the question should be displayed; false otherwise.
 */
export const shouldShowQuestion = (question: Question, answers: QuestionnaireAnswers): boolean => {
  if (!question.orLogic) return true;

  return some(question.orLogic, (values, key) => (values ? includes(values, answers[key]) : false));
};

/**
 * Validates date-type questions in the provided pages to check if they contain valid dates.
 *
 * @param pages Question[][]
 * @param answers QuestionnaireAnswers
 * @param showErrorNotification (message: string) => void
 * @returns True if all date-type questions are valid, otherwise false.
 */
export const validateDateQuestions = (
  pages: Question[][],
  answers: QuestionnaireAnswers,
  showErrorNotification: (message: string) => void
): boolean => {
  return pages.flat().every((q) => {
    if (q.type === 'Date' && answers[q.slug]) {
      const { error } = validDob(answers[q.slug] as string);

      if (error) {
        showErrorNotification(error);
        return false;
      }
    }

    return true;
  });
};
