import { usePDF } from '@react-pdf/renderer';
import { ApiOrder } from 'client/dist/generated/alloy';
import { PdfInvoice } from 'components/dashboard/account/order-history/PdfInvoice';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  order: ApiOrder;
  onDownloaded?: () => void;
  btnClass?: string;
}

export default function DownloadInvoiceButton({
  order,
  onDownloaded,
  btnClass = 'primary-button',
}: Props) {
  const customer = useAppSelector((state) => state.alloy.customer!);

  const orderId = order.fulfillmentCorrelationId;
  const orderNumber = orderId.substring(0, 11).toUpperCase();

  const [instance] = usePDF({
    document: <PdfInvoice orderNumber={orderNumber} order={order} customer={customer} />,
  });

  const handleDownload = () => {
    if (instance.url) {
      const link = document.createElement('a');
      link.href = instance.url;
      link.download = `alloy-invoice-${orderNumber.toLowerCase()}.pdf`;
      link.click();

      !!onDownloaded && onDownloaded();
    }
  };

  return (
    <button onClick={handleDownload} className={btnClass}>
      {instance.loading ? 'Downloading...' : 'Download Invoice'}
    </button>
  );
}
