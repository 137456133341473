/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * api
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr';
import { customInstance } from '../authenticatingAxiosInstance';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubmissionByCategoriesExistsTimeUnit = {
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerStatus = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WidgetStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    PENDING: 'PENDING',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpandOmitAllEventFieldsChargeOrInvoiceOrSubscriptionSubscriptionEditType = {
    RESCHEDULE: 'RESCHEDULE',
    SHIP_NOW: 'SHIP_NOW',
    PAUSE: 'PAUSE',
    CANCEL: 'CANCEL',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpandOmitAllEventFieldsChargeOrInvoiceOrSubscriptionCancellationSource = {
    RETOOL: 'RETOOL',
    CUSTOMER_DASHBOARD: 'CUSTOMER_DASHBOARD',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionGroupConsentStatus = {
    subscribed: 'subscribed',
    unsubscribed: 'unsubscribed',
    opted_in: 'opted_in',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageSource = {
    clinician: 'clinician',
    support: 'support',
    system: 'system',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenewalReason = {
    ZERO_REFILLS_LEFT: 'ZERO_REFILLS_LEFT',
    EXPIRING: 'EXPIRING',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
    SESSION_START: 'SESSION_START',
    REGISTRATION: 'REGISTRATION',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    CONSULT_PLACED: 'CONSULT_PLACED',
    ORDER_PLACED: 'ORDER_PLACED',
    SUBSCRIBE_TO_NEWSLETTER: 'SUBSCRIBE_TO_NEWSLETTER',
    SUBSCRIBE_TO_SMS_TRANSACTIONAL: 'SUBSCRIBE_TO_SMS_TRANSACTIONAL',
    SUBSCRIBE_TO_SMS_MARKETING: 'SUBSCRIBE_TO_SMS_MARKETING',
    SUBSCRIBE_TO_SMS_NOTIFICATIONS: 'SUBSCRIBE_TO_SMS_NOTIFICATIONS',
    UPDATE_WITH_REFERRAL_CODE: 'UPDATE_WITH_REFERRAL_CODE',
    NEW_PRESCRIPTION: 'NEW_PRESCRIPTION',
    TREATMENT_PLAN_READY: 'TREATMENT_PLAN_READY',
    TREATMENT_PLAN_UPDATED: 'TREATMENT_PLAN_UPDATED',
    SEND_PAYMENT: 'SEND_PAYMENT',
    NEW_DOCTOR_MESSAGE: 'NEW_DOCTOR_MESSAGE',
    NEW_CX_MESSAGE: 'NEW_CX_MESSAGE',
    ORDER_SHIPPED: 'ORDER_SHIPPED',
    ORDER_DELIVERED: 'ORDER_DELIVERED',
    ADDON_BUNDLED: 'ADDON_BUNDLED',
    REFILL_REMINDER: 'REFILL_REMINDER',
    FAILED_PAYMENT: 'FAILED_PAYMENT',
    REFERRAL_REDEMPTION: 'REFERRAL_REDEMPTION',
    PAUSED_BY_CUSTOMER: 'PAUSED_BY_CUSTOMER',
    REFUND_ISSUED: 'REFUND_ISSUED',
    ENCOUNTER_CANCELED: 'ENCOUNTER_CANCELED',
    SUBSCRIPTION_BUNDLED: 'SUBSCRIPTION_BUNDLED',
    SUBSCRIPTION_UNBUNDLED: 'SUBSCRIPTION_UNBUNDLED',
    SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
    RENEWAL_KICKOFF: 'RENEWAL_KICKOFF',
    CUSTOMER_STARTED_RENEWAL: 'CUSTOMER_STARTED_RENEWAL',
    RENEWAL_COMPLETION: 'RENEWAL_COMPLETION',
    PRESCRIPTION_AUTOPAUSED: 'PRESCRIPTION_AUTOPAUSED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePaymentMethodType = {
    acss_debit: 'acss_debit',
    affirm: 'affirm',
    afterpay_clearpay: 'afterpay_clearpay',
    alipay: 'alipay',
    amazon_pay: 'amazon_pay',
    au_becs_debit: 'au_becs_debit',
    bacs_debit: 'bacs_debit',
    bancontact: 'bancontact',
    blik: 'blik',
    boleto: 'boleto',
    card: 'card',
    card_present: 'card_present',
    cashapp: 'cashapp',
    customer_balance: 'customer_balance',
    eps: 'eps',
    fpx: 'fpx',
    giropay: 'giropay',
    grabpay: 'grabpay',
    ideal: 'ideal',
    interac_present: 'interac_present',
    klarna: 'klarna',
    konbini: 'konbini',
    link: 'link',
    mobilepay: 'mobilepay',
    multibanco: 'multibanco',
    oxxo: 'oxxo',
    p24: 'p24',
    paynow: 'paynow',
    paypal: 'paypal',
    pix: 'pix',
    promptpay: 'promptpay',
    revolut_pay: 'revolut_pay',
    sepa_debit: 'sepa_debit',
    sofort: 'sofort',
    swish: 'swish',
    twint: 'twint',
    us_bank_account: 'us_bank_account',
    wechat_pay: 'wechat_pay',
    zip: 'zip',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
    PAID: 'PAID',
    SENT_TO_FULFILLMENT: 'SENT_TO_FULFILLMENT',
    ERROR: 'ERROR',
    SHIPPED: 'SHIPPED',
    DELIVERED: 'DELIVERED',
    MANUAL_INTERVENTION: 'MANUAL_INTERVENTION',
    RESEND: 'RESEND',
    REQUEUE: 'REQUEUE',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
    CANCELLED: 'CANCELLED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckoutType = {
    RENEWAL: 'RENEWAL',
    MEDICAL_INTAKE: 'MEDICAL_INTAKE',
    CONSULT: 'CONSULT',
    OTC: 'OTC',
    CUSTOMER_ADDON_REQUEST: 'CUSTOMER_ADDON_REQUEST',
    CUSTOMER_FORM_FACTOR_CHANGE: 'CUSTOMER_FORM_FACTOR_CHANGE',
    CUSTOMER_DOSAGE_CHANGE: 'CUSTOMER_DOSAGE_CHANGE',
    CANCEL_RETENTION: 'CANCEL_RETENTION',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegionType = {
    ALL: 'ALL',
    WEST: 'WEST',
    MIDWEST: 'MIDWEST',
    NORTHEAST: 'NORTHEAST',
    SOUTH: 'SOUTH',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PickStripeStripePriceExcludeKeyofstripeStripePriceDeletedObject = {
    price: 'price',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeProductType = {
    good: 'good',
    service: 'service',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeTaxCodeObject = {
    tax_code: 'tax_code',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceObject = {
    price: 'price',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceTransformQuantityRound = {
    down: 'down',
    up: 'up',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceTiersMode = {
    graduated: 'graduated',
    volume: 'volume',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceTaxBehavior = {
    exclusive: 'exclusive',
    inclusive: 'inclusive',
    unspecified: 'unspecified',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceRecurringUsageType = {
    licensed: 'licensed',
    metered: 'metered',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceRecurringInterval = {
    day: 'day',
    month: 'month',
    week: 'week',
    year: 'year',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceRecurringAggregateUsage = {
    last_during_period: 'last_during_period',
    last_ever: 'last_ever',
    max: 'max',
    sum: 'sum',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeDeletedProductObject = {
    product: 'product',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeProductObject = {
    product: 'product',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceCurrencyOptionsTaxBehavior = {
    exclusive: 'exclusive',
    inclusive: 'inclusive',
    unspecified: 'unspecified',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceBillingScheme = {
    per_unit: 'per_unit',
    tiered: 'tiered',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripePriceType = {
    one_time: 'one_time',
    recurring: 'recurring',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurexaShippingMethod = {
    usps_first: 'usps_first',
    ups_second_day_air: 'ups_second_day_air',
    ups_next_day_air: 'ups_next_day_air',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingCarrier = {
    UPS: 'UPS',
    USPS: 'USPS',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShippingMethodType = {
    STANDARD: 'STANDARD',
    TWO_DAY: 'TWO_DAY',
    NEXT_DAY: 'NEXT_DAY',
    LEGACY: 'LEGACY',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntakeCategory = {
    'skin-health': 'skin-health',
    mht: 'mht',
    'sexual-health': 'sexual-health',
    renewal: 'renewal',
    'vaginal-health': 'vaginal-health',
    'hair-health': 'hair-health',
    'symptom-survey': 'symptom-survey',
    'heard-about-us-survey': 'heard-about-us-survey',
    'doctor-survey': 'doctor-survey',
    cancel: 'cancel',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnbundleSubscriptionType = {
    RESCHEDULE: 'RESCHEDULE',
    SHIP_NOW: 'SHIP_NOW',
    PAUSE: 'PAUSE',
    CANCEL: 'CANCEL',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    PAUSED: 'PAUSED',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TreatmentPlanStatus = {
    AWAITING_DOCTOR: 'AWAITING_DOCTOR',
    PROCESSING: 'PROCESSING',
    PENDING_CX: 'PENDING_CX',
    AWAITING_CUSTOMER: 'AWAITING_CUSTOMER',
    ACTIVE: 'ACTIVE',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenewalInfoReason = {
    EXPIRING: 'EXPIRING',
    ZERO_REFILLS: 'ZERO_REFILLS',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeCouponObject = {
    coupon: 'coupon',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeStripeCouponDuration = {
    forever: 'forever',
    once: 'once',
    repeating: 'repeating',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecurrenceType = {
    ONE_TIME: 'ONE_TIME',
    RECURRING: 'RECURRING',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormFactorType = {
    Pill: 'Pill',
    Patch: 'Patch',
    Gel: 'Gel',
    Spray: 'Spray',
    Cream: 'Cream',
    Face_Cream: 'Face Cream',
    'N/A': 'N/A',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCategory = {
    mht: 'mht',
    'non-hormonal-treatment': 'non-hormonal-treatment',
    'birth-control': 'birth-control',
    'vaginal-health': 'vaginal-health',
    'skin-health': 'skin-health',
    'sexual-health': 'sexual-health',
    'gut-health': 'gut-health',
    'hair-health': 'hair-health',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlloyProductType = {
    OTC: 'OTC',
    RX: 'RX',
    SERVICE: 'SERVICE',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MDIProductType = {
    compound: 'compound',
    medication: 'medication',
    encounter: 'encounter',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PickPendingChargeIdOrStatusOrShippingMethodIdStatus = {
    READY: 'READY',
    PENDING_CUSTOMER_APPROVAL: 'PENDING_CUSTOMER_APPROVAL',
    CUSTOMER_REJECTED: 'CUSTOMER_REJECTED',
    MANUAL_INTERVENTION: 'MANUAL_INTERVENTION',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
    PENDING_CX_APPROVAL: 'PENDING_CX_APPROVAL',
    CX_REQUESTED: 'CX_REQUESTED',
};
export const getTreatmentPlan = () => {
    return customInstance({ url: `/treatment-plan`, method: 'GET' });
};
export const getGetTreatmentPlanKey = () => [`/treatment-plan`];
export const useGetTreatmentPlan = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetTreatmentPlanKey() : null));
    const swrFn = () => getTreatmentPlan();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getPendingSwitch = () => {
    return customInstance({ url: `/treatment-plan/pending-switch`, method: 'GET' });
};
export const getGetPendingSwitchKey = () => [`/treatment-plan/pending-switch`];
export const useGetPendingSwitch = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetPendingSwitchKey() : null));
    const swrFn = () => getPendingSwitch();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const submitTreatmentPlan = (treatmentPlanOrderRequest) => {
    return customInstance({
        url: `/treatment-plan/submit`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: treatmentPlanOrderRequest,
    });
};
export const addToTreatmentPlan = (pickTreatmentPlanOrderRequestExcludeKeyofTreatmentPlanOrderRequestPendingChargeIds) => {
    return customInstance({
        url: `/treatment-plan/add`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickTreatmentPlanOrderRequestExcludeKeyofTreatmentPlanOrderRequestPendingChargeIds,
    });
};
export const viewedPendingTreatmentPlan = (treatmentPlanViewedRequest) => {
    return customInstance({
        url: `/treatment-plan/viewed-pending`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: treatmentPlanViewedRequest,
    });
};
export const getCalculatedTax = (taxesShipping) => {
    return customInstance({
        url: `/taxjar`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: taxesShipping,
    });
};
export const validateAddress = (pickCustomerCityOrZipOrStateAbbrOrShippingAddressLineOne) => {
    return customInstance({
        url: `/taxjar/validate`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickCustomerCityOrZipOrStateAbbrOrShippingAddressLineOne,
    });
};
export const runSuggestions = (suggestionPayload) => {
    return customInstance({
        url: `/suggestion`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: suggestionPayload,
    });
};
export const getAllSubscriptionsForCustomer = () => {
    return customInstance({ url: `/subscriptions`, method: 'GET' });
};
export const getGetAllSubscriptionsForCustomerKey = () => [`/subscriptions`];
export const useGetAllSubscriptionsForCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllSubscriptionsForCustomerKey() : null));
    const swrFn = () => getAllSubscriptionsForCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
/**
 * TODO: more details in the ticket below, but ideally we remove this endpoint and attach isProcessing onto the
actual subscription object that goes to the FE in the getAllSubscriptionForCustomer func above

ticket: https://app.shortcut.com/myalloy/story/18407
 */
export const isProcessing = (subId) => {
    return customInstance({ url: `/subscriptions/${subId}/isProcessing`, method: 'GET' });
};
export const getIsProcessingKey = (subId) => [`/subscriptions/${subId}/isProcessing`];
export const useIsProcessing = (subId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!subId;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getIsProcessingKey(subId) : null));
    const swrFn = () => isProcessing(subId);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const setNextRecurrence = (subId, millis, setNextRecurrenceBody) => {
    return customInstance({
        url: `/subscriptions/${subId}/set-next-recurrence/${millis}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: setNextRecurrenceBody,
    });
};
export const pauseSubscription = (subId, pauseSubscriptionBody) => {
    return customInstance({
        url: `/subscriptions/${subId}/pause`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pauseSubscriptionBody,
    });
};
export const unnpauseSubscription = (subId) => {
    return customInstance({
        url: `/subscriptions/${subId}/unpause`,
        method: 'POST',
    });
};
export const billSubscriptionNow = (subId, billSubscriptionNowBody) => {
    return customInstance({
        url: `/subscriptions/${subId}/bill_now`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: billSubscriptionNowBody,
    });
};
export const reactivateSubscription = (subId) => {
    return customInstance({ url: `/subscriptions/${subId}/reactivate`, method: 'POST' });
};
export const applyPromoCode = (subId, code) => {
    return customInstance({
        url: `/subscriptions/${subId}/promo/apply/${code}`,
        method: 'POST',
    });
};
export const removePromoCode = (subId) => {
    return customInstance({ url: `/subscriptions/${subId}/promo/remove`, method: 'POST' });
};
export const bundleSubscriptions = (bundleSubscription) => {
    return customInstance({
        url: `/subscriptions/bundle`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: bundleSubscription,
    });
};
/**
 * TODO: to not mess with more functions than NEEDED for the moment especially messing with a lot of things that work. I propose later to adjust
the EDITING* of subscriptions by just having probably one endpoint that has a field such as level: 'product' | 'subscription' which
has similar behavior as below where it unbundles a product if at the product level and then does the needful for ship now, reschedule, or pause
or doing it at the subscription level. this imo will help clean out a few endpoints above and remove potential redundancy.
see ticket: https://app.shortcut.com/myalloy/story/18761
 */
export const unbundleProductFromSubscription = (unbundleSubscription) => {
    return customInstance({
        url: `/subscriptions/unbundle`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: unbundleSubscription,
    });
};
export const cancel = (cancelSubscriptionProducts) => {
    return customInstance({
        url: `/subscriptions/cancel`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: cancelSubscriptionProducts,
    });
};
export const postSubmission = (pickSubmissionExcludeKeyofSubmissionId) => {
    return customInstance({
        url: `/submission`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickSubmissionExcludeKeyofSubmissionId,
    });
};
export const updatePartialSubmission = (partialSubmission) => {
    return customInstance({
        url: `/submission`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: partialSubmission,
    });
};
export const getRecentSubmission = (params) => {
    return customInstance({ url: `/submission`, method: 'GET', params });
};
export const getGetRecentSubmissionKey = (params) => [`/submission`, ...(params ? [params] : [])];
export const useGetRecentSubmission = (params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetRecentSubmissionKey(params) : null));
    const swrFn = () => getRecentSubmission(params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getSubmissions = () => {
    return customInstance({ url: `/submission/me`, method: 'GET' });
};
export const getGetSubmissionsKey = () => [`/submission/me`];
export const useGetSubmissions = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetSubmissionsKey() : null));
    const swrFn = () => getSubmissions();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const submissionByCategoriesExists = (params) => {
    return customInstance({ url: `/submission/me/exists`, method: 'GET', params });
};
export const getSubmissionByCategoriesExistsKey = (params) => [`/submission/me/exists`, ...(params ? [params] : [])];
export const useSubmissionByCategoriesExists = (params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getSubmissionByCategoriesExistsKey(params) : null));
    const swrFn = () => submissionByCategoriesExists(params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const postPreIdentifiedSubmission = (pickSubmissionExcludeKeyofSubmissionIdOrCustomerId) => {
    return customInstance({
        url: `/preidentified-submission`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: pickSubmissionExcludeKeyofSubmissionIdOrCustomerId,
    });
};
export const updatePreIdentifiedPartialSubmission = (partialOmitSubmissionCustomerId) => {
    return customInstance({
        url: `/preidentified-submission`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: partialOmitSubmissionCustomerId,
    });
};
export const handleStripeWebhook = (handleStripeWebhookBody) => {
    return customInstance({
        url: `/stripe/webhook`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: handleStripeWebhookBody,
    });
};
export const signUpCustomer = (unsavedCognitoCustomer) => {
    return customInstance({
        url: `/signup`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: unsavedCognitoCustomer,
    });
};
export const getShippingMethods = (state) => {
    return customInstance({
        url: `/shipping-method/${state}`,
        method: 'GET',
    });
};
export const getGetShippingMethodsKey = (state) => [`/shipping-method/${state}`];
export const useGetShippingMethods = (state, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!state;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetShippingMethodsKey(state) : null));
    const swrFn = () => getShippingMethods(state);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllShippingMethods = () => {
    return customInstance({ url: `/shipping-method`, method: 'GET' });
};
export const getGetAllShippingMethodsKey = () => [`/shipping-method`];
export const useGetAllShippingMethods = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllShippingMethodsKey() : null));
    const swrFn = () => getAllShippingMethods();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getOrderCarrier = (orderId) => {
    return customInstance({ url: `/shipping-method/carrier/${orderId}`, method: 'GET' });
};
export const getGetOrderCarrierKey = (orderId) => [`/shipping-method/carrier/${orderId}`];
export const useGetOrderCarrier = (orderId, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!orderId;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetOrderCarrierKey(orderId) : null));
    const swrFn = () => getOrderCarrier(orderId);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const startRenewalAlert = (renewalProductNames) => {
    return customInstance({
        url: `/renewal/start`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: renewalProductNames,
    });
};
export const processRenewalComplete = (renewalPayload) => {
    return customInstance({
        url: `/renewal/complete`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: renewalPayload,
    });
};
export const checkValidity = (code, params) => {
    return customInstance({
        url: `/promotion-code/validity/${code}`,
        method: 'GET',
        params,
    });
};
export const getCheckValidityKey = (code, params) => [`/promotion-code/validity/${code}`, ...(params ? [params] : [])];
export const useCheckValidity = (code, params, options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false && !!code;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getCheckValidityKey(code, params) : null));
    const swrFn = () => checkValidity(code, params);
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllProductFrequencies = () => {
    return customInstance({ url: `/products`, method: 'GET' });
};
export const getGetAllProductFrequenciesKey = () => [`/products`];
export const useGetAllProductFrequencies = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllProductFrequenciesKey() : null));
    const swrFn = () => getAllProductFrequencies();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getNonCustomerUpsellProducts = () => {
    return customInstance({
        url: `/products-customer/non-customer-upsell-products`,
        method: 'GET',
    });
};
export const getGetNonCustomerUpsellProductsKey = () => [`/products-customer/non-customer-upsell-products`];
export const useGetNonCustomerUpsellProducts = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetNonCustomerUpsellProductsKey() : null));
    const swrFn = () => getNonCustomerUpsellProducts();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const createDosageSwitchReason = (createDosageSwitchReasonBody) => {
    return customInstance({
        url: `/product-switch/reason`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createDosageSwitchReasonBody,
    });
};
export const processProductSwitch = (productSwitchPayload) => {
    return customInstance({
        url: `/product-switch/process`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: productSwitchPayload,
    });
};
export const sendSessionStart = (sessionStart) => {
    return customInstance({
        url: `/prepurchase/session-start`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: sessionStart,
    });
};
export const getMessage = () => {
    return customInstance({ url: `/ping`, method: 'GET' });
};
export const getGetMessageKey = () => [`/ping`];
export const useGetMessage = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetMessageKey() : null));
    const swrFn = () => getMessage();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllOrders = () => {
    return customInstance({ url: `/orders`, method: 'GET' });
};
export const getGetAllOrdersKey = () => [`/orders`];
export const useGetAllOrders = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllOrdersKey() : null));
    const swrFn = () => getAllOrders();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getAllUnreadNotifications = () => {
    return customInstance({
        url: `/notifications/notifications`,
        method: 'GET',
    });
};
export const getGetAllUnreadNotificationsKey = () => [`/notifications/notifications`];
export const useGetAllUnreadNotifications = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetAllUnreadNotificationsKey() : null));
    const swrFn = () => getAllUnreadNotifications();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const markNotificationsAsReadByEventType = (markNotificationsAsReadByEventTypeBody) => {
    return customInstance({
        url: `/notifications/notifications/read-by-event-type`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: markNotificationsAsReadByEventTypeBody,
    });
};
/**
 * Subscribe the given email to the newsletter. Unauthenticated and used in marketing
and dashboard sites.
 */
export const subscribeToNewsletter = (subscribeToNewsletterBody) => {
    return customInstance({
        url: `/newsletter/subscribe`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: subscribeToNewsletterBody,
    });
};
export const handleMDIWebhook = (handleMDIWebhookBody) => {
    return customInstance({
        url: `/mdi/webhook`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: handleMDIWebhookBody,
    });
};
export const signup = (landingPageSignup) => {
    return customInstance({
        url: `/landing-page-signup/signup`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: landingPageSignup,
    });
};
export const uploadIdentificationPhoto = (uploadIdentificationPhotoBody) => {
    const formData = new FormData();
    formData.append('file', uploadIdentificationPhotoBody.file);
    return customInstance({
        url: `/fileupload`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
};
export const licenseExistsByCustomer = () => {
    return customInstance({ url: `/fileupload/license/exists`, method: 'GET' });
};
export const getLicenseExistsByCustomerKey = () => [`/fileupload/license/exists`];
export const useLicenseExistsByCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getLicenseExistsByCustomerKey() : null));
    const swrFn = () => licenseExistsByCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const uploadVideo = (uploadVideoBody) => {
    const formData = new FormData();
    formData.append('file', uploadVideoBody.file);
    return customInstance({
        url: `/fileupload/av-sync`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
};
export const avSyncExistsByCustomer = () => {
    return customInstance({ url: `/fileupload/av-sync/exists`, method: 'GET' });
};
export const getAvSyncExistsByCustomerKey = () => [`/fileupload/av-sync/exists`];
export const useAvSyncExistsByCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getAvSyncExistsByCustomerKey() : null));
    const swrFn = () => avSyncExistsByCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getDiscountBundles = () => {
    return customInstance({
        url: `/discount-bundle`,
        method: 'GET',
    });
};
export const getGetDiscountBundlesKey = () => [`/discount-bundle`];
export const useGetDiscountBundles = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetDiscountBundlesKey() : null));
    const swrFn = () => getDiscountBundles();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomer = () => {
    return customInstance({ url: `/customer/me`, method: 'GET' });
};
export const getGetCustomerKey = () => [`/customer/me`];
export const useGetCustomer = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerKey() : null));
    const swrFn = () => getCustomer();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerReferralCode = () => {
    return customInstance({ url: `/customer/me/referral-code`, method: 'GET' });
};
export const getGetCustomerReferralCodeKey = () => [`/customer/me/referral-code`];
export const useGetCustomerReferralCode = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerReferralCodeKey() : null));
    const swrFn = () => getCustomerReferralCode();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerReferralCredits = () => {
    return customInstance({ url: `/customer/me/referral-credits`, method: 'GET' });
};
export const getGetCustomerReferralCreditsKey = () => [`/customer/me/referral-credits`];
export const useGetCustomerReferralCredits = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerReferralCreditsKey() : null));
    const swrFn = () => getCustomerReferralCredits();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerReferralCreditBalance = () => {
    return customInstance({ url: `/customer/me/referral-credit-balance`, method: 'GET' });
};
export const getGetCustomerReferralCreditBalanceKey = () => [`/customer/me/referral-credit-balance`];
export const useGetCustomerReferralCreditBalance = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerReferralCreditBalanceKey() : null));
    const swrFn = () => getCustomerReferralCreditBalance();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const patchCustomer = (partialOmitUnsavedCustomerEmail) => {
    return customInstance({
        url: `/customer`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: partialOmitUnsavedCustomerEmail,
    });
};
export const getMdiWidget = () => {
    return customInstance({ url: `/customer/mdi-widget`, method: 'GET' });
};
export const getGetMdiWidgetKey = () => [`/customer/mdi-widget`];
export const useGetMdiWidget = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetMdiWidgetKey() : null));
    const swrFn = () => getMdiWidget();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getCustomerDoctor = () => {
    return customInstance({ url: `/customer/doctor`, method: 'GET' });
};
export const getGetCustomerDoctorKey = () => [`/customer/doctor`];
export const useGetCustomerDoctor = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetCustomerDoctorKey() : null));
    const swrFn = () => getCustomerDoctor();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const curexaWebhook = (curexaWebhookBody) => {
    return customInstance({
        url: `/curexa/webhook`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: curexaWebhookBody,
    });
};
export const processCheckoutV2 = (checkoutV2Payload) => {
    return customInstance({
        url: `/checkout-v2`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: checkoutV2Payload,
    });
};
export const processCancelRetention = (cancelRetentionPayload) => {
    return customInstance({
        url: `/checkout-v2/cancel-retention`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: cancelRetentionPayload,
    });
};
export const generateSetupIntent = () => {
    return customInstance({
        url: `/checkout-v2/generate-setup-intent`,
        method: 'GET',
    });
};
export const getGenerateSetupIntentKey = () => [`/checkout-v2/generate-setup-intent`];
export const useGenerateSetupIntent = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGenerateSetupIntentKey() : null));
    const swrFn = () => generateSetupIntent();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const getPaymentMethods = () => {
    return customInstance({
        url: `/checkout-v2/get-payment-methods`,
        method: 'GET',
    });
};
export const getGetPaymentMethodsKey = () => [`/checkout-v2/get-payment-methods`];
export const useGetPaymentMethods = (options) => {
    var _a;
    const { swr: swrOptions } = options !== null && options !== void 0 ? options : {};
    const isEnabled = (swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.enabled) !== false;
    const swrKey = (_a = swrOptions === null || swrOptions === void 0 ? void 0 : swrOptions.swrKey) !== null && _a !== void 0 ? _a : (() => (isEnabled ? getGetPaymentMethodsKey() : null));
    const swrFn = () => getPaymentMethods();
    const query = useSwr(swrKey, swrFn, swrOptions);
    return Object.assign({ swrKey }, query);
};
export const attachPaymentMethod = (attachPaymentMethodBody) => {
    return customInstance({
        url: `/checkout-v2/attach-payment-method`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: attachPaymentMethodBody,
    });
};
