import { Skeleton } from '@mui/material';
import {
  DeepProduct,
  useGenerateSetupIntent,
  useGetPaymentMethods,
} from 'client/dist/generated/alloy';
import ProductsList from 'components/dashboard/shared/products/ProductsList';
import PauseModal from 'components/deprecated/PauseModal';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { useQueryParams } from 'context/shared/url/query';
import { upperCase } from 'lodash';
import { isValidManageType, ManageLevel, ManageType } from 'models/components/shared/manage-type';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderInfoCardBlock from '../cards/HeaderInfoCard';
import OrderSummaryCardBlock from '../cards/OrderSummaryCard';
import ManageDrawer from '../drawers/ManageDrawer';
import { isLastDeepProductsIn } from 'lib/shared/subscriptions/product';

export default function ManageWrapper() {
  const location = useLocation();

  const { getParam, navigateWithParams } = useQueryParams();

  const { subscription, isLoading } = useSubscriptionContext();

  const [isManageOpen, setIsManageOpen] = useState(false);

  // TODO: remove code once cancel flag is removed
  const [isPauseOpen, setIsPauseOpen] = useState(false);

  const [selectedDeepProductIds, setSelectedDeepProductIds] = useState<number[]>([]);
  const [manageType, setManageType] = useState<ManageType>('EDIT');
  const [manageLevel, setManageLevel] = useState<ManageLevel>('SUBSCRIPTION');

  const { isLoading: isLoadingIntent } = useGenerateSetupIntent();
  const { isLoading: isPaymentMethodsLoading } = useGetPaymentMethods();

  const isLoadingData = isLoading || isLoadingIntent || isPaymentMethodsLoading;

  useEffect(() => {
    const productIdsString = getParam('deepProductIds[]');
    const manage = upperCase(getParam('manage') || '').replaceAll(' ', '_');

    if (isValidManageType(manage) && !isLoadingData) {
      const convertedProductIds = (productIdsString || '').split(',').map(Number);
      const subscriptionDeeProductIds = subscription.products.map((pfr) => pfr.product.id);

      if (
        convertedProductIds.length &&
        convertedProductIds.every((dpId) => subscriptionDeeProductIds.includes(dpId)) &&
        !isLastDeepProductsIn(subscription, convertedProductIds)
      ) {
        setSelectedDeepProductIds(
          subscription.products
            .map((pfr) => pfr.product.id)
            .filter((dpId) => convertedProductIds.includes(dpId))
        );
        setManageLevel('PRODUCT');
      } else {
        setSelectedDeepProductIds(subscriptionDeeProductIds);
        setManageLevel('SUBSCRIPTION');
      }

      setManageType(manage);
      setIsManageOpen(true);
    }
  }, [location.search, isLoadingData]);

  const onEditProduct = (deepProductIds: DeepProduct['id'][]) =>
    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`, {
      manage: 'edit',
      'deepProductIds[]': deepProductIds.join(','),
    });

  const onRescheduleSub = () =>
    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`, {
      manage: 'reschedule',
    });

  const onShipNowSub = () =>
    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`, {
      manage: 'ship_now',
    });

  const onClose = () => {
    setIsManageOpen(false);

    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`);
  };

  // TODO: remove code once cancel flag is removed
  const onPause = () => {
    setIsManageOpen(false);

    navigateWithParams(`/subscriptions/${subscription.stripeSubscriptionId}`);

    setIsPauseOpen(true);
  };

  if (isLoadingData) {
    return (
      <>
        <Skeleton variant='rectangular' height={200} className='mb-3' />
        <Skeleton variant='rectangular' height={200} />
      </>
    );
  }

  if (!!subscription) {
    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <div className='subscription-container'>
              <div className='subscription-content-wrapper'>
                <HeaderInfoCardBlock onReschedule={onRescheduleSub} onShipNow={onShipNowSub} />
              </div>

              <div className='subscription-content-wrapper'>
                <div className='products-card-block'>
                  <h3 className='card-title'>
                    Your Treatments <span className='title-hug'>Every 3 months</span>
                  </h3>

                  <ProductsList
                    productsWithRenewal={subscription.products}
                    subscriptions={[subscription]}
                    showDosage
                    onEdit={onEditProduct}
                  />
                </div>
              </div>

              <div className='subscription-content-wrapper'>
                <OrderSummaryCardBlock subscription={subscription} />
              </div>
            </div>
          </div>
        </div>

        {!!selectedDeepProductIds.length && (
          <ManageDrawer
            selectedDeepProductIds={selectedDeepProductIds}
            selectedType={manageType}
            manageLevel={manageLevel}
            open={isManageOpen}
            onClose={onClose}
            onPause={onPause}
          />
        )}

        {/* TODO: remove code once cancel flag is removed */}
        {!!selectedDeepProductIds.length && (
          <PauseModal
            selectedDeepProductIds={selectedDeepProductIds}
            manageLevel={manageLevel}
            open={isPauseOpen}
            setOpen={setIsPauseOpen}
          />
        )}
      </>
    );
  }

  return <></>;
}
