import { CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Form } from 'rsuite';

import closeIcon from 'assets/svg/btn-close.svg';

import { TextField } from 'components/core/fields/TextField';

interface Props {
  activeDiscountName?: string;
  isLoading?: boolean;
  onApply: (code: string) => void;
  onRemove: () => void;
}

export default function DiscountBlock({
  activeDiscountName,
  isLoading = false,
  onApply,
  onRemove,
}: Props) {
  const [showPromoCode, setShowPromoCode] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');

  const onApplyCode = () => {
    onApply(promoCode);

    setPromoCode('');
  };

  const onRemoveCode = () => {
    onRemove();

    setPromoCode('');
  };

  if (isLoading) {
    return (
      <div className='manage-discount-wrapper'>
        <CircularProgress size={25} />
      </div>
    );
  }

  if (!!activeDiscountName) {
    return (
      <div className='manage-discount-wrapper'>
        <div className='discount-content'>
          <button onClick={onRemoveCode} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <p className='discount-text'>Active Discount: {activeDiscountName}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='manage-discount-wrapper'>
      {showPromoCode ? (
        <div className='discount-content'>
          <button onClick={() => setShowPromoCode(false)} className='discount-close-btn'>
            <img src={closeIcon} alt='close icon' className='close-icon' />
          </button>

          <div className='dicount-input-wrapper'>
            <Form>
              <TextField
                name='code'
                placeholder='Discount code'
                classes='discount-input'
                value={promoCode}
                onChange={(value) => setPromoCode(value.toUpperCase())}
              />

              <button
                className='primary-button'
                onClick={onApplyCode}
                disabled={isEmpty(promoCode)}
              >
                Apply
              </button>
            </Form>
          </div>
        </div>
      ) : (
        <button className='discount-link' onClick={() => setShowPromoCode(true)}>
          Have a Promo Code?
        </button>
      )}
    </div>
  );
}
