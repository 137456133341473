import { useEffect, useState } from 'react';

import ConfirmationBlock from '../cancel/ConfirmationBlock';
import RetentionBlock from '../cancel/RetentionBlock';
import StartBlock from '../cancel/StartBlock';

import { reasons } from 'data/dashboard/cancel/reason';
import { useQueryParams } from 'context/shared/url/query';
import { upperCase } from 'lodash';
import { CancelStep } from 'models/components/dashboard/subscriptions/cancel';

export default function CancelWrapper() {
  const { setParam, getParam } = useQueryParams();

  const [cancelStep, setCancelStep] = useState<CancelStep>('START');

  const outcome = upperCase(getParam('outcome') || '');

  useEffect(() => {
    // based on whether we find the reason slug or if there is outcome, we need to update state of
    // cancel step so that customer can be shown one of the blocks below
    const foundReasonSlug = getParam('reason');
    const reason = reasons.find((r) => r.slug === foundReasonSlug);

    if (!!outcome || !!reason) setCancelStep('RETENTION');
  }, []);

  const onReschedule = () => {
    setParam('manage', 'reschedule');
  };

  const onContinue = (step: CancelStep) => setCancelStep(step);

  return (
    <div className='cancel-wrapper'>
      {cancelStep === 'START' && <StartBlock onReschedule={onReschedule} onContinue={onContinue} />}

      {cancelStep === 'RETENTION' && !outcome && <RetentionBlock />}

      {outcome && <ConfirmationBlock />}
    </div>
  );
}
