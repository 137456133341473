import classNames from 'classnames';
import { IconText } from 'models/components/shared/icon-text';

interface Props {
  data: IconText[];
  customBg?: string;
}

export default function FeaturesBlock({ data, customBg }: Props) {
  return (
    <div className={classNames(customBg, 'features-block')}>
      {data.map((item, index) => (
        <div className='feature-wrapper' key={index}>
          <img src={item.icon} alt={item.text} className='feature-img' />
          <p className='feature-text'>{item.text}</p>
        </div>
      ))}
    </div>
  );
}
