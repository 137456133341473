import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import QuestionnaireWrapper from 'components/dashboard/shared/drawers/wrappers/QuestionnaireWrapper';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useQueryParams } from 'context/shared/url/query';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { useState } from 'react';

export default function NotFeelingBetterContent() {
  const { onCancel, onRetentionSave } = useCancelContext();
  const { getParam, setParam } = useQueryParams();

  const [answers, setAnswers] = useState<QuestionnaireAnswers>({});

  const questions = getIntakesByCategory(['cancel']);

  const onContinue = async (qAnswers: QuestionnaireAnswers) => {
    setAnswers(qAnswers);

    setParam('section', 'confirm');
  };

  if (getParam('section') === 'confirm') {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Your doctor can help you find a better fit</h4>
          <p className='content-text'>
            We can send your answers to your doctor to review and find the right medication for your
            needs.
          </p>
        </div>

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={() => onRetentionSave(answers)}>
            Send to my doctor
          </button>

          <button className='primary-link' onClick={() => onCancel(answers)}>
            I want to cancel instead
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <QuestionnaireWrapper
        questions={questions}
        previousAnswers={answers}
        onContinue={onContinue}
      />
    );
  }
}
