import { TextAreaField } from 'components/core/fields/TextAreaField';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { isEmpty } from 'lodash';
import { useState } from 'react';

export default function OtherContent() {
  const [inputText, setInputText] = useState('');

  const { onCancel } = useCancelContext();

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>Please let us know why you'd like to cancel:</h4>
      </div>

      <form>
        <TextAreaField
          name='other-field'
          value={inputText}
          onChange={setInputText}
          placeholder='Type here'
        />
      </form>

      <div className='btns-stack-block'>
        <button
          className='primary-button'
          disabled={isEmpty(inputText)}
          onClick={() => onCancel({ 'cancel-explanation': inputText })}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
