import { SelectFieldsGroup } from 'components/core/fields/SelectFieldsGroup';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useProductContext } from 'context/dashboard/subscriptions/product';
import { reasons, ReasonType } from 'data/dashboard/cancel/reason';
import { getFilteredReasonsForOptions } from 'lib/dashboard/subscriptions/cancel/filtered-reasons';
import { formatProductNamesList } from 'lib/shared/product/name';

interface Props {
  onContinue: () => void;
}

export default function ReasonContainer({ onContinue }: Props) {
  const { product } = useProductContext();
  const { reasonType, setReasonType } = useCancelContext();

  return (
    <div className='cancel-reason-container'>
      <div className='content-block'>
        <h4 className='content-title'>We're sorry to see you go!</h4>
        <p className='content-text'>
          Why would you like to cancel your subscription of {formatProductNamesList([product])}?
        </p>
      </div>

      <SelectFieldsGroup
        selectedIds={reasonType ? [reasonType] : []}
        onChange={(checkboxAnswers) => {
          const singleChoiceAnswer: ReasonType =
            (checkboxAnswers[checkboxAnswers.length - 1] as ReasonType) ?? '';

          setReasonType(singleChoiceAnswer);
        }}
        options={getFilteredReasonsForOptions(product)}
        isSingleSelect
      />

      <button className='primary-button' disabled={!reasonType} onClick={onContinue}>
        Continue
      </button>
    </div>
  );
}
