import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { CheckboxOption } from 'components/core/fields/SelectFieldsGroup';
import { CancelReason, reasons } from 'data/dashboard/cancel/reason';

/**
 * Filters the reasons since some are based on category and we don't want to show mht specific
 * reasons in cancel flow for a product of m4 and allows to return as a CheckboxOption[] to render
 *
 * @param product GroupedContentfulProduct
 * @returns CheckboxOption[]
 */
export const getFilteredReasonsForOptions = (
  product: GroupedContentfulProduct
): CheckboxOption[] => {
  const categories = product.alloyProduct.parent.map((dp) => dp.category);
  const filteredReasons = reasons.filter((r) => r.categories.some((c) => categories.includes(c)));

  return filteredReasons.map((option) => ({
    id: option.type,
    text: option.text,
  }));
};
