import { SubscriptionWithRenewal } from 'client/dist/generated/alloy';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { DeepProduct } from 'common/dist/products/productFrequency';
import { getDeepProductIdsFrom } from '../product';

/**
 * Checks whether the customer is on their last "grouped" (for mht purposes) product
 * in their account on active subs
 *
 * @param activeSubs SubscriptionWithRenewal[]
 * @param groupedProduct GroupedContentfulProduct
 * @returns boolean
 */
export const isLastGroupedProductIn = (
  activeSubscriptions: SubscriptionWithRenewal[],
  groupedProduct: GroupedContentfulProduct
): boolean => {
  // Check if there is only one active subscription
  if (activeSubscriptions.length !== 1) return false;

  return isLastDeepProductsIn(activeSubscriptions[0], getDeepProductIdsFrom(groupedProduct));
};

export const isLastDeepProductsIn = (
  subscription: SubscriptionWithRenewal,
  deepProductIds: DeepProduct['id'][]
) => {
  const groupedProductIds = new Set(deepProductIds);

  const remainingProducts = subscription.products.filter(
    (subProduct) => !groupedProductIds.has(subProduct.product.id)
  );

  return remainingProducts.length === 0;
};
