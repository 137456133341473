import { ProductCategory } from 'client/dist/generated/alloy';

export type ReasonType =
  | 'NOT_FEELING_BETTER'
  | 'NOT_SEEING_RESULTS'
  | 'EXPERIENCING_SIDE_EFFECTS'
  | 'TOO_EXPENSIVE'
  | 'DIFFERENT_PRODUCT'
  | 'TOO_MUCH_PRODUCT'
  | 'BAD_EXPERIENCE'
  | 'MOVING'
  | 'NO_SUBSCRIPTION'
  | 'USE_PERSONAL_DOCTOR'
  | 'USE_INSURANCE'
  | 'OTHER';

export type Outcome = 'CANCEL' | 'RETENTION' | 'INVOICE' | 'KEEP';

export type CancelReason = {
  text: string;
  slug: string;
  type: ReasonType;
  categories: ProductCategory[];
};

const allCategories: ProductCategory[] = [
  'mht',
  'non-hormonal-treatment',
  'birth-control',
  'vaginal-health',
  'skin-health',
  'sexual-health',
  'gut-health',
  'hair-health',
];

export const reasons: CancelReason[] = [
  {
    text: "I'm not feeling better",
    slug: 'not-feeling-better',
    type: 'NOT_FEELING_BETTER',
    categories: ['mht', 'vaginal-health'],
  },
  {
    text: "I'm not seeing results",
    slug: 'no-results',
    type: 'NOT_SEEING_RESULTS',
    categories: [
      'non-hormonal-treatment',
      'birth-control',
      'skin-health',
      'sexual-health',
      'gut-health',
      'hair-health',
    ],
  },
  {
    text: 'I am experiencing side effects or adverse effects',
    slug: 'side-effects',
    type: 'EXPERIENCING_SIDE_EFFECTS',
    categories: allCategories,
  },
  {
    text: "It's too expensive",
    slug: 'too-expensive',
    type: 'TOO_EXPENSIVE',
    categories: allCategories,
  },
  {
    text: 'I want a different product',
    slug: 'different-product',
    type: 'DIFFERENT_PRODUCT',
    categories: allCategories,
  },
  {
    text: 'I have too much product',
    slug: 'too-much-product',
    type: 'TOO_MUCH_PRODUCT',
    categories: allCategories,
  },
  {
    text: "I haven't had a good experience with Alloy",
    slug: 'bad-experience',
    type: 'BAD_EXPERIENCE',
    categories: allCategories,
  },
  {
    text: "I'm moving",
    slug: 'moving',
    type: 'MOVING',
    categories: allCategories,
  },
  {
    text: "I don't want a subscription",
    slug: 'no-subscription',
    type: 'NO_SUBSCRIPTION',
    categories: allCategories,
  },
  {
    text: 'I prefer to use my personal doctor',
    slug: 'personal-doctor',
    type: 'USE_PERSONAL_DOCTOR',
    categories: allCategories,
  },
  {
    text: 'I want to use my insurance',
    slug: 'use-insurance',
    type: 'USE_INSURANCE',
    categories: allCategories,
  },
  {
    text: 'Other',
    slug: 'other',
    type: 'OTHER',
    categories: allCategories,
  },
];
