import CheckmarkListBlock from 'components/shared/CheckmarkList';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { AllBenefits } from 'data/dashboard/cancel/all-benefits';
import { useQueryParams } from 'context/shared/url/query';
import DoctorCardBlock from './Doctor';

export default function PersonalDoctorContent() {
  const { onCancel } = useCancelContext();
  const { setParam } = useQueryParams();

  const onKeepSubscription = () => setParam('outcome', 'keep');

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          Alloy provides unparalleled access to menopause-specialized doctors.
        </h4>
      </div>

      <DoctorCardBlock showAbout />
      <CheckmarkListBlock list={AllBenefits} />

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onKeepSubscription}>
          Keep my subscription
        </button>

        <button className='primary-link' onClick={() => onCancel()}>
          Continue to cancel
        </button>
      </div>
    </div>
  );
}
