import { IconText } from 'models/components/shared/icon-text';
import {
  doctorIcon,
  medicineIcon,
  ongoingIcon,
  truckIcon,
} from 'assets/svg/checkout-experience/expectations';

export const subscriberBenefits: IconText[] = [
  {
    icon: doctorIcon,
    text: 'Menopause-specialized doctors',
  },
  {
    icon: medicineIcon,
    text: 'Medicine delivered to your door',
  },
  {
    icon: truckIcon,
    text: 'Free shipping • Cancel anytime',
  },
  {
    icon: ongoingIcon,
    text: 'Ongoing care and education',
  },
];
