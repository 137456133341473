import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { createContext, ReactNode, useContext } from 'react';

interface Props {
  product: GroupedContentfulProduct;
}

// we know a product will always exist at this level
export const ProductContext = createContext<Props>({
  product: {} as GroupedContentfulProduct,
});

export const useProductContext = () => {
  const product = useContext(ProductContext);

  return product;
};

export default function ProductContextProvider({
  children,
  product,
}: {
  children: ReactNode;
  product: GroupedContentfulProduct;
}) {
  return (
    <ProductContext.Provider
      value={{
        product,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
