import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Form } from 'rsuite';

import { confirmRegistration } from 'actions/auth/verification_actions';

import arrowRight from 'assets/svg/core/arrows/arrow-right-white.svg';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import Loader from 'components/core/Loader';
import { showErrorNotification, showSuccessNotification } from 'components/core/Notification';
import { CodeField } from 'components/core/fields/CodeField';
import { EmailField } from 'components/core/fields/EmailField';
import { PasswordField } from 'components/core/fields/PasswordField';
import CheckoutExperienceSection from 'components/core/layout/CheckoutExperienceSection';

import Layout from 'containers/Layout';

import cleanNonNumberValues from 'lib/checkout-experience/authentication/cleanNonNumberValues';
import { getPercentComplete } from 'lib/checkout-experience/progress';
import { resendVerificationCode } from 'lib/core/awsAuth';
import { buildCheckoutExperienceLoginURL } from 'lib/core/url';
import { posthogCapture } from 'lib/tracking/posthog';

import { ExperienceComponentModel } from 'models/alloy/experience';

import { useAppSelector } from 'reducers/alloy_reducer';

type VerificationForm = {
  email: string;
  password: string;
  code: string;
  confirmedChecksSucceeded: boolean;
};

const INIT_VERIFICATION_FORM: VerificationForm = {
  email: '',
  password: '',
  code: '',
  confirmedChecksSucceeded: false,
};

export default function Verification({
  onNext,
  onBack,
  standaloneVerificationParams,
}: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationForm, setVerificationForm] =
    useState<VerificationForm>(INIT_VERIFICATION_FORM);

  const isAuthenticated = useAppSelector((state) => state.alloy.isAuthenticated);
  const cognitoUser = useAppSelector((state) => state.experience.cognitoUser);

  const dispatchConfirmRegistration = bindActionCreators(confirmRegistration, dispatch);

  useEffect(() => {
    if (isAuthenticated && onBack) {
      onBack();
    } else {
      setVerificationForm({
        ...INIT_VERIFICATION_FORM,
        email: cognitoUser?.email || standaloneVerificationParams?.email || '',
      });
    }
  }, []);

  // Submit the login and if needed to change password, submit the new password changed
  const onSubmit = async () => {
    window.scrollTo(0, 0);

    try {
      setIsLoading(true);

      const { email, code } = verificationForm;

      const password = cognitoUser ? cognitoUser.password : verificationForm.password;

      const isStandaloneVerification =
        standaloneVerificationParams?.email || standaloneVerificationParams?.password;

      await dispatchConfirmRegistration(email, password, code, !isStandaloneVerification);

      posthogCapture('accountVerified');

      onNext();
    } catch (error: any) {
      if (error.message && error.message.includes('Incorrect username or password')) {
        showErrorNotification(
          'Account verified but email or password was incorrect. Please login again.'
        );

        navigate(buildCheckoutExperienceLoginURL(location));
      } else {
        setIsLoading(false);
      }
    }
  };

  const onResendCode = async () => {
    try {
      const { email } = verificationForm;

      setIsLoading(true);

      await resendVerificationCode(email);

      setIsLoading(false);

      showSuccessNotification('Verification code resent successfully');
    } catch (error) {
      const errorMessage = (error as Error).message;

      setIsLoading(false);

      showErrorNotification(errorMessage);
    }
  };

  return (
    <Layout title='Verification - Alloy' desc='' noBars>
      <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <CheckoutExperienceSection>
            <h1 className='content-title'>Enter your verification code</h1>
            <p className='content-text'>
              {standaloneVerificationParams?.context?.includes('UNCONFIRMED') &&
                `Your account requires verification. `}
              We sent the verification code to {verificationForm.email}. Enter the code below to
              verify your account. Your code expires after 24 hours.
            </p>

            {(!cognitoUser || !cognitoUser.email) && (
              <EmailField
                name='email'
                label='Email Address'
                value={verificationForm.email}
                placeholder='Enter your email address'
                onChange={(value) => setVerificationForm({ ...verificationForm, email: value })}
              />
            )}

            {(!cognitoUser || !cognitoUser.password) && (
              <PasswordField
                name='password'
                label='Re-Enter Password'
                value={verificationForm.password}
                placeholder='Enter password'
                hideChecksSucceeded
                onChange={(_, value) =>
                  setVerificationForm({ ...verificationForm, password: value })
                }
              />
            )}

            <CodeField
              name='code'
              label='Code'
              placeholder='Enter code here'
              value={verificationForm.code}
              onChange={(value) => {
                setVerificationForm({
                  ...verificationForm,
                  code: cleanNonNumberValues(value, 6),
                });
              }}
            />

            {verificationForm.email && (
              <div className='content-resend-code-wrapper'>
                <p className='resend-code-title'>Didn't receive a code?</p>
                <p className='resend-code-text'>
                  We sent the verification code to {verificationForm.email}. Be sure to check your
                  promotions and spam folders in case the email is redirected!
                </p>

                <button type='button' onClick={onResendCode} className='primary-link-button'>
                  Resend code
                </button>
              </div>
            )}

            <button
              className='primary-button full-width-button'
              type='submit'
              disabled={
                verificationForm.email === undefined ||
                verificationForm.email === '' ||
                (!cognitoUser && verificationForm.password === '') ||
                verificationForm.code === '' ||
                verificationForm.code.length !== 6
              }
              onClick={onSubmit}
            >
              Verify account
              <img src={arrowRight} alt='arrow right' className='btn-arrow' />
            </button>
          </CheckoutExperienceSection>
        </Form>
      )}
    </Layout>
  );
}
