import {
  bundleSubscriptions,
  SubscriptionWithRenewal,
  useGetAllSubscriptionsForCustomer,
} from 'client/dist/generated/alloy';
import { useEffect, useState } from 'react';

import chevronDownIcon from 'assets/svg/core/chevron-down.svg';

import classNames from 'classnames';
import Loader from 'components/core/Loader';
import AlloyDrawer from 'components/core/drawers/AlloyDrawer';
import ProductsList from 'components/dashboard/shared/products/ProductsList';
import OptionsSelectList from 'components/shared/OptionsSelectList';
import { format, getUnixTime } from 'date-fns';
import { buildDataForCalculation, calculateTaxes } from 'lib/checkout-experience/checkout/tax';
import { convertCentsToDollars } from 'lib/shared/convert';
import { getTotalsForSubscriptions } from 'lib/shared/subscriptions';
import { SummaryContent } from 'models/alloy/dashboard-base';
import { Option } from 'models/components/shared/options-select-list';
import { useAppSelector } from 'reducers/alloy_reducer';
import { SEVENTY_FIVE_DAY_RECURRING_ID } from 'common/dist/products/productRegistry';
import { first, uniq, uniqBy } from 'lodash';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  bundableSubscriptions: SubscriptionWithRenewal[];
  setDidBundle: (didBundle: boolean) => void;
}

export default function BundleDrawer({
  open,
  setOpen,
  bundableSubscriptions,
  setDidBundle,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const customer = useAppSelector((state) => state.alloy.customer!);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();

  const [selectedSubId, setSelectedSubId] = useState(
    first(bundableSubscriptions)?.stripeSubscriptionId ?? ''
  );
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [taxInCents, setTaxInCents] = useState(0);

  const { subtotal } = getTotalsForSubscriptions(bundableSubscriptions);

  const hasBirthControlSubscription = subscriptions.some((sub) =>
    sub.products.some((pfr) => pfr.product.frequencyId === SEVENTY_FIVE_DAY_RECURRING_ID)
  );

  useEffect(() => {
    const getTaxAmount = async () => {
      const data = buildDataForCalculation(
        0,
        0,
        bundableSubscriptions.flatMap((sub) => sub.products.map((pfr) => pfr.product.priceInCents)),
        customer
      );

      const tax = await calculateTaxes(data);

      setTaxInCents(tax * 100);

      setIsLoading(false);
    };

    getTaxAmount();
  }, []);

  // construct options to display for shipping date
  const options: Option[] = uniqBy(
    bundableSubscriptions
      .map((sub) => ({
        id: sub.stripeSubscriptionId,
        title: format(new Date(sub.nextRecurrenceOn), 'MM/dd/yyyy'),
      }))
      .concat([
        {
          id: '',
          title: 'Ship now',
        },
      ]),
    (option) => option.title
  );

  // construct summary content for summary section to view
  const summaryContent: SummaryContent[] = [
    {
      title: 'Subtotal',
      content: `$${convertCentsToDollars(subtotal)}`,
    },
    {
      title: 'Taxes',
      content: `$${convertCentsToDollars(taxInCents)}`,
    },
    {
      title: 'Shipping',
      content: `$0.00`,
    },
  ];

  const onConfirm = async () => {
    setIsLoading(true);

    const selectedStartDate = bundableSubscriptions.find(
      (sub) => sub.stripeSubscriptionId === selectedSubId
    )?.nextRecurrenceOn;

    const startTimestamp = !!selectedStartDate
      ? getUnixTime(new Date(selectedStartDate))
      : undefined;

    await bundleSubscriptions({
      productFrequencyIds: bundableSubscriptions.flatMap((sub) =>
        sub.products.map((pfr) => pfr.product.id)
      ),
      stripeSubscriptionIds: bundableSubscriptions.map((sub) => sub.stripeSubscriptionId),
      startTimestamp,
    });

    setDidBundle(true);
    setOpen(false);
  };

  return (
    <AlloyDrawer
      title='Bundle your shipments'
      drawerClass='bundle-drawer'
      open={open}
      onClose={() => setOpen(false)}
      disableClosing={isLoading}
    >
      {isLoading ? (
        <Loader size='lg' />
      ) : (
        <>
          <div className='drawer-body'>
            <div className='date-options-content-wrapper'>
              <p className='content-title'>Select your ship date</p>

              <OptionsSelectList
                options={options}
                selectedId={selectedSubId}
                onSelect={(id) => setSelectedSubId(id)}
              />
            </div>

            <div className='products-summary-content-wrapper'>
              <ProductsList
                productsWithRenewal={bundableSubscriptions.flatMap((sub) => sub.products)}
                subscriptions={bundableSubscriptions}
                showDosage
              />

              <div className='content-divider' />

              <div className={classNames('summary-block', isSummaryOpen && 'is-open')}>
                <div
                  className='summary-header-wrapper'
                  onClick={() => setIsSummaryOpen(!isSummaryOpen)}
                >
                  <p className='summary-title'>Bundled Total</p>

                  <p className='summary-text'>
                    ${convertCentsToDollars(subtotal + taxInCents)}
                    <img src={chevronDownIcon} alt='chevron-down' className='chevron-icon' />
                  </p>
                </div>

                <div className='summary-content-wrapper'>
                  {summaryContent.map((summary, index) => (
                    <div className='content-row' key={index}>
                      <p className='content-title'>{summary.title}</p>
                      <p className='content-text'>{summary.content}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {hasBirthControlSubscription && (
              <div className='informational-content-wrapper'>
                <p className='content-title'>Why can't I bundle my low-dose birth control?</p>
                <p className='content-text'>
                  Low-dose birth control requires a unique shipping cadence to ensure you receive
                  your medication on time. To maintain this precise schedule, we must ship it
                  separately from other products.
                </p>
              </div>
            )}
          </div>

          <div className='drawer-footer'>
            <button className='primary-button full-width-button' onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </>
      )}
    </AlloyDrawer>
  );
}
