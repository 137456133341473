import { subscriberBenefits } from 'data/dashboard/cancel/subscriber-benefits';
import DoctorCardBlock from './Doctor';

interface Props {
  showDoctor?: boolean;
}

export default function SubscriberBenefitsBlock({ showDoctor }: Props) {
  return (
    <div className='subscriber-benefits-block'>
      <p className='benefits-title'>Your subscriber benefits</p>

      <div className='benefits-perks-list'>
        {subscriberBenefits.map((benefit, index) => (
          <div className='perk-item' key={index}>
            <img src={benefit.icon} alt={benefit.text} className='perk-img' />
            <p className='perk-text'>{benefit.text}</p>
          </div>
        ))}
      </div>

      {showDoctor && <DoctorCardBlock />}
    </div>
  );
}
