import Loader from 'components/core/Loader';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import BadExperienceContent from '../content/BadExperience';
import DifferentProductContent from '../content/DifferentProduct';
import DownloadInvoiceContent from '../content/DownloadInvoice';
import MovingContent from '../content/Moving';
import NoResultsContent from '../content/NoResults';
import NoSubscriptionContent from '../content/NoSubscription';
import NotFeelingBetterContent from '../content/NotFeelingBetter';
import OtherContent from '../content/Other';
import PersonalDoctorContent from '../content/PersonalDoctor';
import SideEffectsContent from '../content/SideEffects';
import TooMuchProductContent from '../content/TooMuchProduct';

const ReasonsContent = () => {
  const { reasonType } = useCancelContext();

  switch (reasonType) {
    case 'NOT_FEELING_BETTER':
      return <NotFeelingBetterContent />;

    case 'NOT_SEEING_RESULTS':
      return <NoResultsContent />;

    case 'EXPERIENCING_SIDE_EFFECTS':
      return <SideEffectsContent />;

    case 'TOO_EXPENSIVE':
      return <DownloadInvoiceContent />;

    case 'DIFFERENT_PRODUCT':
      return <DifferentProductContent />;

    case 'TOO_MUCH_PRODUCT':
      return <TooMuchProductContent />;

    case 'BAD_EXPERIENCE':
      return <BadExperienceContent />;

    case 'MOVING':
      return <MovingContent />;

    case 'NO_SUBSCRIPTION':
      return <NoSubscriptionContent />;

    case 'USE_PERSONAL_DOCTOR':
      return <PersonalDoctorContent />;

    case 'USE_INSURANCE':
      return <DownloadInvoiceContent />;

    default:
      return <OtherContent />;
  }
};

export default function FollowUpContainer() {
  const { isLoading } = useCancelContext();

  if (isLoading) return <Loader />;

  return (
    <div className='cancel-follow-up-container'>
      <ReasonsContent />
    </div>
  );
}
