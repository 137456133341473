import {
  Customer,
  getCustomer,
  licenseExistsByCustomer,
  updatePartialSubmission,
} from 'client/dist/generated/alloy';
import { Dispatch } from '@reduxjs/toolkit';

import {
  CUSTOMER,
  IS_APP_LOADED,
  IS_AUTHENTICATED,
  LICENSE_EXISTS,
  LOCAL_USER,
  MESSAGES_BANNER,
  REQUESTING_PRODUCTS,
  TOP_ALERT_BANNER,
} from 'actions/types';

import { getAlertBanner, getMessagesBanner } from 'lib/shared/contentful';
import { brazeInit, brazeSetPartial, identifyBraze } from 'lib/tracking/braze';
import { correlateFreshpaintToCustomer, identifyFreshpaint } from 'lib/tracking/freshpaint';
import { identifySentry, sentryInit } from 'lib/tracking/sentry';

import { currentSession } from 'lib/core/awsAuth';
import { identifyPosthog, posthogLoaded } from 'lib/tracking/posthog';
import { RootState } from 'reducers';
import { LocalPreCustomer } from 'reducers/experience_reducer';
import { getItem, setItem } from 'client/dist/localstorage';

/**
 *
 * @returns {(function(*): Promise<any|{}|undefined>)|*}
 */
const initializeApp = () => {
  return async (dispatch: Dispatch) => {
    const localPreCustomer = getItem('localPreCustomer', true) ?? {};
    const requestingDeepProductIds = getItem('requestingDeepProductIds', true) ?? [];

    // Need to either get or define the local products.
    // This is mainly for the recommendation flow for a users previously selected products
    // (if they had selected before and come back)

    // Local data is very minimal:
    // - email, state, marketing email consent, date of birth, submission id (along with submission categories + date created)
    // - requesting products (this is just what the customer would like in consult shift != cart)
    dispatch({ type: LOCAL_USER, payload: localPreCustomer });
    dispatch({ type: REQUESTING_PRODUCTS, payload: requestingDeepProductIds });

    brazeInit();
    sentryInit();

    try {
      const [userCurrentSession, topAlertBanner, messagesBanner, _] = await Promise.all([
        currentSession(),
        getAlertBanner(),
        getMessagesBanner(),
        posthogLoaded(),
      ]);

      dispatch({ type: TOP_ALERT_BANNER, payload: topAlertBanner });
      dispatch({ type: MESSAGES_BANNER, payload: messagesBanner });

      if (!userCurrentSession?.isValid()) {
        dispatch({ type: IS_AUTHENTICATED, payload: false });
        dispatch({ type: IS_APP_LOADED });
        return;
      }

      const [alloyCustomer, licenseExists] = await Promise.all([
        getCustomer(),
        licenseExistsByCustomer(),
      ]);

      postIdentification(alloyCustomer);

      dispatch({ type: CUSTOMER, payload: alloyCustomer });

      dispatch({ type: LICENSE_EXISTS, payload: licenseExists });

      dispatch({ type: IS_AUTHENTICATED, payload: true });

      dispatch({ type: IS_APP_LOADED });

      return alloyCustomer;
    } catch (error) {
      console.log(error);
      dispatch({ type: IS_AUTHENTICATED, payload: false });

      dispatch({ type: IS_APP_LOADED });

      return {};
    }
  };
};

/**
 * Once we've "identified" the user we need to
 * * Update tracking vendors Logrocket, freshpaint, sentry, and braze
 * * Update our database so we can link preidentification med intake to alloy customer
 *
 * Leaving here for now with the initializeApp
 */
const postIdentification = async (customer: Customer, submissionId?: string) => {
  try {
    if (submissionId) {
      await updatePartialSubmission({ id: submissionId, customerId: customer.id });
    }

    identifyFreshpaint(customer);
    correlateFreshpaintToCustomer();

    identifyBraze(customer);

    brazeSetPartial(customer);

    identifySentry(customer);

    identifyPosthog(customer);
  } catch (error) {
    throw error;
  }
};

const updateLocalPreCustomer = (updatedLocalPreCustomer: Partial<LocalPreCustomer>) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const localPreCustomer = {
      ...getState().experience.localPreCustomer,
      ...updatedLocalPreCustomer,
    };

    setItem('localPreCustomer', localPreCustomer, true);

    dispatch({ type: LOCAL_USER, payload: localPreCustomer });
  };
};

export { initializeApp, postIdentification, updateLocalPreCustomer };
