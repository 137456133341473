import { ExperienceCategory } from 'common/dist/models/experience';

export interface PersonalizedContent {
  title: string;
  text: string;
  step: 'name' | 'register';
  categories: ExperienceCategory[];
}

const DEFAULT_CREATE_ACCOUNT_TITLE = 'Create a secure account';

export const defaultCreateAccountContent: PersonalizedContent = {
  title: DEFAULT_CREATE_ACCOUNT_TITLE,
  text: 'Create an account to keep your information secure and message with your doctor. With your account, you can message your doctor directly.',
  step: 'register',
  categories: [],
};

export const createAccountContent: PersonalizedContent[] = [
  defaultCreateAccountContent,
  {
    title: DEFAULT_CREATE_ACCOUNT_TITLE,
    text: 'Manage your skincare prescription and securely message your doctor with any questions.',
    step: 'register',
    categories: ['skin-health'],
  },
  {
    title: DEFAULT_CREATE_ACCOUNT_TITLE,
    text: 'Manage your sexual wellness prescription and securely message your doctor with any questions.',
    step: 'register',
    categories: ['sexual-health'],
  },
];

export const defaultNameContent: PersonalizedContent = {
  title: 'Tell us about you',
  text: "We'll use this to personalize your experience and match you with a doctor licensed in your state.",
  step: 'name',
  categories: [],
};

export const nameContent: PersonalizedContent[] = [
  defaultNameContent,
  {
    title: "Let's start your relief plan.",
    text: 'In under 2 weeks, 95% of customers see real symptom relief.',
    step: 'name',
    categories: ['mht'],
  },
  {
    title: 'Revive Radiant Skin',
    text: 'Within 3 months, most customers see significant improvements in their skin.',
    step: 'name',
    categories: ['skin-health'],
  },
  {
    title: 'Get your groove back!',
    text: 'Start your custom sexual health presecription below.',
    step: 'name',
    categories: ['sexual-health', 'vaginal-health'],
  },
];
