import {
  CheckoutType,
  DeepProduct,
  PreSubmission,
  ProductCategory,
  Submission,
  SubmissionAnswer,
  postSubmission,
  processProductSwitch,
  runSuggestions,
  useGetAllSubscriptionsForCustomer,
  useGetPendingSwitch,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';

import { updateCart } from 'actions/checkout-experience/cart_actions';

import TopBannerWithProgress from 'components/checkout-experience/TopBannerWithProgress';
import QuestionnaireRoutes from 'components/checkout-experience/questionnaire/QuestionnaireRoutes';
import Loader from 'components/core/Loader';
import Disqualified from 'components/request-experience/questionnaire/Disqualified';

import Layout from 'containers/Layout';

import { getPercentComplete } from 'lib/checkout-experience/progress';
import { collateHiddenSubmissionAnswers } from 'lib/shared/questionnaire/intake';
import { cleanPurchasableProducts, retrieveFlowFromUrl } from 'lib/request-experience/flow';
import { getIntakeCategories, retrieveCategoriesFromUrl } from 'lib/shared/experience';
import { getPreviousResults } from 'lib/shared/questionnaire';

import { ExperienceComponentModel } from 'models/alloy/experience';
import { QuestionnaireAnswers } from 'models/components/questionnaire';

import { useAppSelector } from 'reducers/alloy_reducer';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';
import { ExperienceCategory } from 'common/dist/models/experience';

export default function Intake({ onNext, onBack }: ExperienceComponentModel) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const dateStarted = new Date();

  const flow = retrieveFlowFromUrl(location);
  const categories = retrieveCategoriesFromUrl(location);

  const intakeQuestions = flow.intakeQuestions!!;

  const [previousResults, setPreviousResults] = useState<QuestionnaireAnswers>({});
  const [isLoading, setIsLoading] = useState(true);
  const [disqualifiedProducts, setDisqualifiedProducts] = useState<DeepProduct[]>([]);

  const customer = useAppSelector((state) => state.alloy.customer);
  const localPreCustomer = useAppSelector((state) => state.experience.localPreCustomer);
  const cart = useAppSelector((state) => state.experience.alloyCart);

  const dispatchUpdateCart = bindActionCreators(updateCart, dispatch);

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutatePendingSwitch } = useGetPendingSwitch();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const previousResults = getPreviousResults(intakeQuestions);

    setPreviousResults(previousResults);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (categories.every((c) => c === 'mht') && cart.products.length === 0) {
      navigate('/', { replace: true });
    }
  }, []);

  const onSubmit = async (answers: SubmissionAnswer[]) => {
    try {
      setIsLoading(true);

      const intakeCategories = getIntakeCategories(categories);
      const questions = flow.intakeQuestions!!;

      const updatedAnswers = collateHiddenSubmissionAnswers(
        answers,
        questions,
        localPreCustomer,
        customer
      );

      const submission: PreSubmission = {
        categories: intakeCategories,
        started: dateStarted,
        answers: updatedAnswers,
      };

      const { id } = await postSubmission(submission);

      // for switch flow, we need to handle the completion a little differently since it is a switch
      // process vs a direct request of a product
      if (searchParams.has('type') && searchParams.get('type') === 'switch') {
        await handleSwitchFlow(categories, id);
      } else {
        await handleNonSwitchFlow(id);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // handles the product switch change processing
  const handleSwitchFlow = async (categories: ExperienceCategory[], submissionId: string) => {
    setIsLoading(true);

    const checkoutType = searchParams.get('checkoutType');
    const currentPfIds = searchParams.get('currentPfIds[]');
    const requestedPfIds = searchParams.get('requestedPfIds[]');
    const subscriptionId = searchParams.get('subscriptionId');

    // this shouldn't happen since we check for this to exist in url before even
    // getting into mht request experience
    if (!currentPfIds || !requestedPfIds || !checkoutType || !subscriptionId) {
      setIsLoading(false);
      return;
    }

    const convCurrentPfIds = currentPfIds.split(',').map((id) => Number(id));
    const convRequestedPfIds = requestedPfIds.split(',').map((id) => Number(id));

    await processProductSwitch({
      checkoutType: checkoutType as CheckoutType,
      currentPfIds: convCurrentPfIds,
      requestedPfIds: convRequestedPfIds,
      submissionId,
      subscriptionId: subscriptionId,
      categoryType: categories[0] as ProductCategory, // send the first in the array since switch is only for one category at a time
    });

    await mutatePendingSwitch();

    navigate({
      pathname: '/request-confirmation',
      search: `?${searchParams.toString()}`,
    });
  };

  // moved this out of the submit func for non mht

  /**
   * request-experience starts with 'default' products based on getEligibleUpsellProducts returned from productSerivce
   *
   * we pass different values to the cart between RE and CE because of the inital
   * product selection offered from each experience by which ensures appropriate dosages are selected
   */
  const handleNonSwitchFlow = async (submissionId?: string) => {
    // TODO: some suggestions for tret and others should be here
    if (submissionId) {
      const suggestions = await runSuggestions({ submissionId });

      // Handle cleaning requested products, this can be particular for the piece around m4 in subs
      // but wants to request tret and needs to see cheaper price
      const { activeSubs, pausedSubs } = getSubscriptionsWithStatus(subscriptions);
      const activeSubscriptions = [...activeSubs, ...pausedSubs];

      const activeSubsProducts = activeSubscriptions.flatMap((sub) =>
        sub.products.map((pfr) => pfr.product)
      );

      const qualifiedProducts = suggestions.qualified
        .map((dq) => dq.product)
        .filter(
          (pf) =>
            !activeSubsProducts.some((asp) =>
              ProductRegistry.get().areProductsEquivalent([pf, asp])
            )
        );

      if (qualifiedProducts.length === 0) {
        setDisqualifiedProducts(suggestions.disqualified.map((dq) => dq.product));
        setIsLoading(false);
        return;
      }

      const filteredProducts = await cleanPurchasableProducts(
        qualifiedProducts,
        activeSubsProducts
      );

      dispatchUpdateCart({
        products: filteredProducts,
      });
    }

    onNext();
  };

  if (isLoading) {
    return (
      <Layout title='Intake - Alloy' desc='' noBars>
        <TopBannerWithProgress percentComplete={getPercentComplete(location)} />

        <Loader />
      </Layout>
    );
  }

  if (disqualifiedProducts.length !== 0) return <Disqualified products={disqualifiedProducts} />;

  return (
    <QuestionnaireRoutes
      questions={intakeQuestions}
      previousResults={previousResults}
      onSubmit={onSubmit}
      onBack={onBack}
      parentUrlPath='/request-experience/intake'
    />
  );
}
