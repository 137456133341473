import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { ProductCleanNameDosage } from '.';
import { DOSAGE_NA } from 'common/dist/products/productRegistry';
import ProductRegistry from 'client/dist/product/productRegistry';
import _ from 'lodash';

/**
 * get the name for all products combined from alloy product. this allows us to get dosage and then
 * formatting it to the user.
 *
 * @param groupedProduct GroupedContentfulProduct
 * @returns string
 */
export const getProductNameWithDosage = (
  groupedProduct: GroupedContentfulProduct
): ProductCleanNameDosage[] => {
  const { alloyProduct } = groupedProduct;
  const allProducts = [...alloyProduct.parent, ...(alloyProduct.child ?? [])].flat();

  return allProducts.map((p) => ({
    cleanName: ProductRegistry.get().getProductFullName(p),
    ...(p.dosageId !== DOSAGE_NA && { dosage: p.dose }),
  }));
};

/**
 *
 * @param products GroupedContentfulProduct[]
 * @returns string
 */
export const formatProductNamesList = (products: GroupedContentfulProduct[]): string => {
  if (products.length === 0) return '';

  const names = _(products)
    .flatMap((product) => [
      ...product.alloyProduct.parent.map((parent) =>
        ProductRegistry.get().getProductFullName(parent)
      ),
      ...(product.alloyProduct.child?.map((child) =>
        ProductRegistry.get().getProductFullName(child)
      ) || []),
    ])
    .uniq()
    .value();

  if (names.length === 1) {
    return names[0];
  } else if (names.length === 2) {
    return `${names[0]} and ${names[1]}`;
  } else {
    const lastName = names.pop();

    return `${names.join(', ')}, and ${lastName}`;
  }
};
