import { DeepProduct } from 'client/dist/generated/alloy';
import { TextAreaField } from 'components/core/fields/TextAreaField';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useProductContext } from 'context/dashboard/subscriptions/product';
import { getDeepProductIdsFrom } from 'lib/shared/product';
import { getProductsWithAltDoses } from 'lib/shared/product/switch';
import { useQueryParams } from 'context/shared/url/query';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export default function NoResultsContent() {
  const { product } = useProductContext();
  const { navigateWithParams } = useQueryParams();

  const [altDoses, setAltDoses] = useState<DeepProduct[]>([]);
  const [inputText, setInputText] = useState('');

  const { onCancel } = useCancelContext();

  useEffect(() => {
    const getAltDoses = async () => {
      const productsWithAltDoses = await getProductsWithAltDoses(product);
      setAltDoses(productsWithAltDoses);
    };

    getAltDoses();
  }, []);

  const onChangeDose = () =>
    navigateWithParams('/treatment-plan', {
      manage: 'change',
      'deepProductIds[]': getDeepProductIdsFrom(product).join(','),
    });

  if (altDoses.length > 0) {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Did you know you can change your dosage?</h4>
          <p className='content-text'>
            If you aren't happy with your treatment, you can easily switch your dose.
          </p>
        </div>

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onChangeDose}>
            Change my dose
          </button>

          <button className='primary-link' onClick={() => onCancel()}>
            I want to cancel
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Tell us more about what you're experiencing:</h4>
        </div>

        <form>
          <TextAreaField
            name='no-results-field'
            value={inputText}
            onChange={setInputText}
            placeholder='Type here'
          />
        </form>

        <div className='btns-stack-block'>
          <button
            className='primary-button'
            disabled={isEmpty(inputText)}
            onClick={() => onCancel({ 'cancel-explanation': inputText })}
          >
            Continue to cancel
          </button>
        </div>
      </div>
    );
  }
}
