import checkmarkIcon from 'assets/svg/core/checkmarks/checkmark.svg';

interface Props {
  list: string[];
}

export default function CheckmarkListBlock({ list }: Props) {
  return (
    <div className='checkmark-list-block'>
      {list.map((text, index) => (
        <div className='list-wrapper'>
          <img className='list-icon' src={checkmarkIcon} alt='checkmark icon' />

          <p key={index} className='list-text'>
            {text}
          </p>
        </div>
      ))}
    </div>
  );
}
