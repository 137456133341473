import { bindActionCreators } from '@reduxjs/toolkit';
import { useGetCustomerDoctor } from 'client/dist/generated/alloy';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { updatePrescribingDoctor } from 'actions/treatment-plan/symptoms_actions';

import { getPrescribingDoctorByNpiId } from 'lib/shared/contentful';

import { useAppSelector } from 'reducers/alloy_reducer';

export default function DoctorBlock() {
  const dispatch = useDispatch();

  const { data: doctorNpiId, isLoading: isLoadingDoctor } = useGetCustomerDoctor();

  const doctor = useAppSelector((state) => state.treatmentPlan.prescribingDoctor);

  const dispatchUpdatePrescribingDoctor = bindActionCreators(updatePrescribingDoctor, dispatch);

  useEffect(() => {
    retrieveDoctor();
  }, [isLoadingDoctor]);

  const retrieveDoctor = async () => {
    if (!!doctorNpiId && !doctor) {
      const fetchedDoctor = await getPrescribingDoctorByNpiId(doctorNpiId);

      if (fetchedDoctor) {
        dispatchUpdatePrescribingDoctor(fetchedDoctor);
      }
    }
  };

  if (!doctor || isLoadingDoctor) return <></>;

  return (
    <div className='doctor-block'>
      <div className='doctor-info-block'>
        <img
          src={doctor.fields.photo?.fields.file.url}
          alt={doctor.fields.name}
          className='doctor-photo'
        />

        <div className='doctor-content'>
          <p className='doctor-title'>Prescribed by</p>
          <p className='doctor-name'>{doctor.fields.name}</p>
          <p className='doctor-text'>{doctor.fields.degrees}</p>

          <Link to='/messages' className='primary-link-button d-flex d-md-none'>
            Message your doctor
          </Link>
        </div>
      </div>

      <div className='d-none d-md-block'>
        <Link to='/messages' className='btn-message'>
          Message your doctor
        </Link>
      </div>
    </div>
  );
}
