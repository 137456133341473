import { bindActionCreators } from '@reduxjs/toolkit';
import { updatePrescribingDoctor } from 'actions/treatment-plan/symptoms_actions';
import { useGetCustomerDoctor } from 'client/dist/generated/alloy';
import { getPrescribingDoctorByNpiId } from 'lib/shared/contentful';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reducers/alloy_reducer';

interface Props {
  showAbout?: boolean;
}

export default function DoctorCardBlock({ showAbout }: Props) {
  const dispatch = useDispatch();

  const { data: doctorNpiId, isLoading: isLoadingDoctor } = useGetCustomerDoctor();

  const doctor = useAppSelector((state) => state.treatmentPlan.prescribingDoctor);

  const dispatchUpdatePrescribingDoctor = bindActionCreators(updatePrescribingDoctor, dispatch);

  useEffect(() => {
    retrieveDoctor();
  }, [isLoadingDoctor]);

  const retrieveDoctor = async () => {
    if (!!doctorNpiId && !doctor) {
      const fetchedDoctor = await getPrescribingDoctorByNpiId(doctorNpiId);

      if (fetchedDoctor) {
        dispatchUpdatePrescribingDoctor(fetchedDoctor);
      }
    }
  };

  if (!!doctor) {
    return (
      <div className='doctor-card-block'>
        <img
          src={doctor.fields.photo?.fields.file.url}
          alt={doctor.fields.name}
          className='doctor-photo'
        />

        <div className='doctor-content'>
          <p className='content-title'>{doctor.fields.name}</p>
          <p className='content-subtitle'>{doctor.fields.degrees}</p>

          <p className='content-text'>
            {showAbout && doctor.fields.about
              ? doctor.fields.about
              : 'Free, unlimited messaging with your doctor is included in your subscription.'}
          </p>
        </div>
      </div>
    );
  }

  return <></>;
}
