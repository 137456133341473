import { TitleText } from 'models/components/title-text';

export const verifyIdentityItems: TitleText[] = [
  {
    title: 'Why am I sending a photo?',
    text: "All doctors are required by law to verify your identity before prescribing any medication. We'll use the photo to verify that it's you.",
  },
  {
    title: 'Who will see my information?',
    text: 'This is stored only on our secure platform. Your prescribing doctor and your patient support team are the only ones who will see it there.',
  },
  {
    title: 'So I can just send a selfie?',
    text: "Yup! Any photo will do. A few tips to make sure we're able to verify your identity: ",
    bullets: [
      'The photo should be only of you.',
      'You should be looking directly at the camera.',
      'There should be nothing covering your face (hair, sunglasses, hats, etc.)',
    ],
  },
];
