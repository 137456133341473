import { useGetCustomerReferralCode } from 'client/dist/generated/alloy';
import usePosthog from 'hooks/shared/usePosthog';
import posthog from 'posthog-js';
import { useState } from 'react';

/**
 * This block is used within the treatment plan active page and is on the right hand side. it serves the propose of allowing a user to copy
 * their referral code and share it with friends! if there is no code, then we do not show the block
 */
export default function ReferralBlock() {
  const [isCopied, setIsCopied] = useState(false);

  const { data: code = '' } = useGetCustomerReferralCode();

  const { isEnabled: isDecember } = usePosthog('december-promotion');

  const onCopy = () => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (code === '') return <></>;

  return (
    <div className='referral-block'>
      <p className='referral-tag'>Refer a friend</p>
      <h3 className='referral-title'>
        {isDecember ? 'Gift a free consult, Get $50' : 'Give $25, Get $15'}
      </h3>

      <p className='referral-text'>
        {isDecember
          ? "Treat a friend to a free consult (worth $49) and you'll receive a $50 credit toward Alloy products. Win-win!"
          : 'Give your friends $25 off their first purchase and receive a $15 credit towards your next order. Limit 1 credit per order.'}
      </p>

      <p className='referral-code-field'>{code}</p>

      <button className='secondary-button' onClick={onCopy} disabled={isCopied}>
        {isCopied ? 'Link copied' : 'Copy link'}
      </button>
    </div>
  );
}
