import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useQueryParams } from 'context/shared/url/query';

export default function TooMuchProductContent() {
  const { setParam } = useQueryParams();

  const { onCancel } = useCancelContext();

  const onReschedule = () => setParam('manage', 'reschedule');

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          Too much product? We can ship your next refill at a later date.
        </h4>
        <p className='content-text'>
          With Alloy, you can easily reschedule your treatments to ship at a later date without
          losing your prescription or access to your doctor. Would you like to reschedule your
          shipment of this treatment?
        </p>
      </div>

      <div className='btns-stack-block'>
        <button className='primary-button' onClick={onReschedule}>
          Yes, reschedule
        </button>

        <button className='primary-link' onClick={() => onCancel()}>
          No, I want to cancel
        </button>
      </div>
    </div>
  );
}
