import { ReferralCredit } from 'client/dist/generated/alloy';
import { isWithinInterval } from 'date-fns';

/**
 * Calculates referral value of all credits a user has redeemed
 * Credits redeemed between Dec 1-31 are worth 50 dollars, all others
 * are 15 (due to December promotion)
 * @param credits
 * @returns the numerical value (in dollars) of the total referrals earned by account
 */
export function getCreditsValue(credits: ReferralCredit[]) {
  const startDate = process.env.REACT_APP_DECEMBER_PROMO_START_DATE || '2024-11-27T00:00:00.000Z';
  const endDate = process.env.REACT_APP_DECEMBER_PROMO_END_DATE || '2024-12-31T23:59:59.999Z';

  return credits
    .map((c) => {
      return isWithinInterval(c.createdAt, {
        start: new Date(startDate),
        end: new Date(endDate),
      })
        ? 50
        : 15;
    })
    .reduce((sum, value) => sum + value, 0);
}
