import classNames from 'classnames';
import {
  createDosageSwitchReason,
  DeepProduct,
  SubscriptionWithRenewal,
  useGetTreatmentPlan,
} from 'client/dist/generated/alloy';
import ProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { first } from 'lodash';
import { useEffect, useState } from 'react';

import Loader from 'components/core/Loader';
import AlloyDrawer from 'components/core/drawers/AlloyDrawer';
import DosageBlock, { DoseForm } from '../containers/DosageBlock';
import TreatmentBlock from '../containers/TreatmentBlock';
import ConfirmChangeDrawer from './ConfirmChangeDrawer';

import {
  ProductFrequenciesWithHasPrescription,
  getSwitchableFormFactorProducts,
} from 'lib/request-experience/switch';
import HeaderBlock, { ProductSwitchChangeType } from '../containers/HeaderBlock';
import { getDeepProductIdsFrom } from 'lib/shared/product';
import { ProductRenewalPrescription } from 'models/alloy/product';
import { getProductsWithAltDoses, getProductsWithAltFormFactors } from 'lib/shared/product/switch';

interface Props {
  open: boolean;
  onClose: () => void;
  product: ProductRenewalPrescription;
  subscription: SubscriptionWithRenewal;
  switchableProductIds: number[];
}

export default function RequestDrawer({
  open,
  onClose,
  product,
  subscription,
  switchableProductIds,
}: Props) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [dosageSwitchReasonId, setDosageSwitchReasonId] = useState<number | undefined>(undefined);

  const [selectedType, setSelectedType] = useState<ProductSwitchChangeType | undefined>(undefined);

  const INIT_FORM: DoseForm = {
    reason: '',
    agreedTerms: false,
  };

  const [altDoseProducts, setAltDoseProducts] = useState<DeepProduct[]>([]);
  const [altFormFactorProducts, setAltFormFactorProducts] = useState<DeepProduct[]>([]);

  const [doseForm, setDoseForm] = useState<DoseForm>(INIT_FORM);

  const [products, setProducts] = useState<ProductFrequenciesWithHasPrescription[]>([]);
  const [groupedProducts, setGroupedProducts] = useState<GroupedContentfulProduct[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<GroupedContentfulProduct>({
    alloyProduct: product.alloyProduct,
    contentfulProduct: product.contentfulProduct,
  });

  const { data: treatmentPlan, isLoading } = useGetTreatmentPlan();

  const hasAltDoses = altDoseProducts.length > 0;
  const hasAltFormFactors = altFormFactorProducts.length > 0;

  const onlyDosageSwitchAvailable = hasAltDoses && !hasAltFormFactors;
  const onlyFormFactorSwitchAvailable = hasAltFormFactors && !hasAltDoses;

  useEffect(() => {
    // check for other products from same product id but with different dosages
    const checkAltDoses = async () => {
      const productsWithAltDoses = await getProductsWithAltDoses(product);
      setAltDoseProducts(productsWithAltDoses);
    };

    // check for other products from same product id but with different form factors
    const checkAltFormFactors = async () => {
      const productsWithAltFormFactors = await getProductsWithAltFormFactors(product);
      setAltFormFactorProducts(productsWithAltFormFactors);
    };

    // get the form factors available for the product to show to them in the drawer
    const getForms = async () => {
      if (open) {
        const activePrescriptions = treatmentPlan?.prescriptionProducts ?? [];

        // todo: confirm this still works if we have a child with multiple form factors in the future
        const parentPf = first(product.alloyProduct.parent)!;
        const childrenPfs = product.alloyProduct.child ?? [];

        const filteredProducts = await getSwitchableFormFactorProducts(
          parentPf,
          childrenPfs,
          activePrescriptions,
          switchableProductIds.filter((id) =>
            altFormFactorProducts.map((p) => p.productId).includes(id)
          )
        );

        const groupedContentful = (
          await ProductRegistry.get().getRecurringProductsForV2(
            filteredProducts.flatMap((fmp) => fmp.productFrequencies)
          )
        ).flat();

        setProducts(filteredProducts);
        setGroupedProducts(groupedContentful);
      }
    };

    checkAltDoses();
    checkAltFormFactors();
    getForms();

    // if the product has other dosages avaiable but NOT other form factors, it can only go for a dosage switch
    if (onlyDosageSwitchAvailable) {
      setSelectedType('CUSTOMER_DOSAGE_CHANGE');
    }

    // if the product has other form factors avaiable but NOT other dosages, it can only go for a form factor switch
    if (onlyFormFactorSwitchAvailable) {
      setSelectedType('CUSTOMER_FORM_FACTOR_CHANGE');
    }
  }, [open]);

  const hasPrescriptionFor = (pfId: number) => {
    return products.some(
      (pfp) => pfp.productFrequencies.some((pf) => pf.id === pfId) && pfp.hasPrescription
    );
  };

  const onOpenConfirm = (gcp: GroupedContentfulProduct) => {
    setSelectedProduct(gcp);
    setIsConfirmOpen(true);
  };

  const onRequestDose = async () => {
    const currentPfIds = getDeepProductIdsFrom(product);

    const dosageSwitchReason = await createDosageSwitchReason({
      reason: doseForm.reason,
      currentPfIds,
    });

    setDosageSwitchReasonId(dosageSwitchReason.id);
    setIsConfirmOpen(true);
  };

  return (
    <>
      <AlloyDrawer
        title='Request a change'
        drawerClass='request-drawer'
        open={open}
        onClose={onClose}
        disableClosing={isLoading}
      >
        {isLoading ? (
          <Loader size='lg' />
        ) : (
          <>
            <div
              className={classNames(
                'drawer-body',
                selectedType === 'CUSTOMER_DOSAGE_CHANGE' && 'dosage'
              )}
            >
              <HeaderBlock
                product={product}
                subscription={subscription}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                onlyDosageSwitch={onlyDosageSwitchAvailable}
                onlyFormFactorSwitch={onlyFormFactorSwitchAvailable}
              />

              <div className='drawer-divider' />

              {selectedType === 'CUSTOMER_FORM_FACTOR_CHANGE' && (
                <TreatmentBlock
                  hasAltFormFactors={hasAltFormFactors}
                  groupedProducts={groupedProducts}
                  hasPrescriptionFor={hasPrescriptionFor}
                  onOpenConfirm={onOpenConfirm}
                />
              )}

              {selectedType === 'CUSTOMER_DOSAGE_CHANGE' && (
                <DosageBlock
                  hasAltDoses={hasAltDoses}
                  doseForm={doseForm}
                  setDoseForm={setDoseForm}
                />
              )}
            </div>

            {selectedType === 'CUSTOMER_DOSAGE_CHANGE' && hasAltDoses && (
              <div className='drawer-footer'>
                <button
                  className='primary-button full-width-button'
                  onClick={onRequestDose}
                  disabled={doseForm.reason === '' || !doseForm.agreedTerms}
                >
                  Continue
                </button>
              </div>
            )}
          </>
        )}
      </AlloyDrawer>

      {selectedType && (
        <ConfirmChangeDrawer
          currentProduct={product}
          requestedProduct={selectedProduct}
          open={isConfirmOpen}
          setOpen={setIsConfirmOpen}
          selectedType={selectedType}
          hasPrescription={hasPrescriptionFor(first(selectedProduct.alloyProduct.parent)!.id)}
          subscription={subscription}
          dosageSwitchReasonId={dosageSwitchReasonId}
        />
      )}
    </>
  );
}
