import { ExperienceCategory } from 'common/dist/models/experience';
import {
  PersonalizedContent,
  createAccountContent,
  defaultCreateAccountContent,
  defaultNameContent,
  nameContent,
} from 'data/checkout-experience/content/personalized-content';

// Gets the correct content in the checkout experience
// depending on the selected category and step.
export function getPersonalizedContent(selectedCategories: ExperienceCategory[], step: string) {
  const bestCategories = selectedCategories.includes('mht') ? ['mht'] : selectedCategories;

  const getFirstMatch = (options: PersonalizedContent[]) =>
    options.find((copy) => copy.categories.some((category) => bestCategories.includes(category)));

  switch (step) {
    case 'register':
      return getFirstMatch(createAccountContent) ?? defaultCreateAccountContent;
    case 'name':
      return getFirstMatch(nameContent) ?? defaultNameContent;
    default:
      return defaultNameContent;
  }
}
