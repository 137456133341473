import {
  cancel,
  postSubmission,
  PreSubmission,
  processCancelRetention,
  Submission,
} from 'client/dist/generated/alloy';
import getIntakesByCategory from 'common/dist/intake/getIntakesByCategory';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import { reasons, ReasonType } from 'data/dashboard/cancel/reason';
import { getAnswersForSubmission } from 'lib/shared/questionnaire/submission';
import { getDeepProductIdsFrom } from 'lib/shared/product';
import { useQueryParams } from 'context/shared/url/query';
import { QuestionnaireAnswers } from 'models/components/questionnaire';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useSubscriptionContext } from './manage';
import ProductContextProvider from './product';
import { isEmpty } from 'lodash';
import { showErrorNotification } from 'components/core/Notification';

interface Props {
  reasonType: ReasonType | null;
  setReasonType: (type: ReasonType | null) => void;

  onCancel: (answers?: QuestionnaireAnswers) => void;
  onRetentionSave: (answers?: QuestionnaireAnswers) => void;

  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const CancelContext = createContext<Props>({
  reasonType: null,
  setReasonType: () => {},

  onCancel: () => {},
  onRetentionSave: () => {},

  isLoading: false,
  setIsLoading: () => {},
});

export const useCancelContext = () => {
  const cancel = useContext(CancelContext);

  return cancel;
};

export default function CancelContextProvider({
  children,
  product,
}: {
  children: ReactNode;
  product: GroupedContentfulProduct;
}) {
  const { subscription } = useSubscriptionContext();
  const { setParam } = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);
  const [reasonType, setReasonType] = useState<ReasonType | null>(null);

  const onCancel = async (answers: QuestionnaireAnswers = {}) => {
    setIsLoading(true);

    await saveSubmission(answers);

    const payload = {
      deepProductIds: getDeepProductIdsFrom(product),
      stripeSubscriptionId: subscription.stripeSubscriptionId,
    };

    await cancel(payload);

    setParam('outcome', 'cancel');

    setIsLoading(false);
  };

  const onRetentionSave = async (answers: QuestionnaireAnswers = {}) => {
    setIsLoading(true);

    const id = await saveSubmission(answers);

    const payload = {
      deepProductIds: getDeepProductIdsFrom(product),
      submissionId: id,
      subscriptionId: subscription.stripeSubscriptionId,
    };

    await processCancelRetention(payload);

    setParam('outcome', 'retention');

    setIsLoading(false);
  };

  const saveSubmission = async (answers: QuestionnaireAnswers = {}) => {
    const reason = reasons.find((r) => r.type === reasonType);

    const updatedAnswers = {
      'cancel-reason': reason?.text || 'ERROR',
      ...answers,
    };

    const results = getAnswersForSubmission(getIntakesByCategory(['cancel']), updatedAnswers);

    const submission: PreSubmission = {
      categories: ['cancel'],
      started: new Date(),
      answers: results,
    };

    const { id } = await postSubmission(submission);

    return id;
  };

  return (
    <CancelContext.Provider
      value={{
        reasonType,
        setReasonType,

        onCancel,
        onRetentionSave,

        isLoading,
        setIsLoading,
      }}
    >
      <ProductContextProvider product={product}>{children}</ProductContextProvider>
    </CancelContext.Provider>
  );
}
