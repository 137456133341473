import { Skeleton } from '@mui/material';
import {
  billSubscriptionNow,
  setNextRecurrence,
  unbundleProductFromSubscription,
  useGetAllSubscriptionsForCustomer,
  useIsProcessing,
} from 'client/dist/generated/alloy';
import DomProductRegistry from 'client/dist/product/productRegistry';
import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';
import AlloyDrawer from 'components/core/drawers/AlloyDrawer';
import { showSuccessNotification } from 'components/core/Notification';
import { useSubscriptionContext } from 'context/dashboard/subscriptions/manage';
import { getUnixTime, isToday, startOfDay } from 'date-fns';
import { getMillisWithRandomTime } from 'lib/shared/date';
import { getDeepProductIdsFrom, getProductToBeBundledWith } from 'lib/shared/product';
import { getUnbundledDeepProductIdsFrom } from 'lib/shared/subscriptions/product-filter';
import { capitalize, first, upperCase } from 'lodash';
import { ManageLevel, ManageType } from 'models/components/shared/manage-type';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditProductWrapper from './wrappers/EditProductWrapper';
import RescheduleWrapper from './wrappers/RescheduleWrapper';
import ShipNowWrapper from './wrappers/ShipNowWrapper';
import CancelWrapper from './wrappers/CancelWrapper';
import CancelContextProvider, { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useQueryParams } from 'context/shared/url/query';

interface Props {
  selectedDeepProductIds: number[];
  selectedType: ManageType;
  manageLevel: ManageLevel;
  open: boolean;
  onClose: () => void;
  onPause: () => void;
}

export default function ManageDrawer({
  selectedDeepProductIds,
  selectedType,
  manageLevel,
  open,
  onClose,
  onPause,
}: Props) {
  const navigate = useNavigate();

  const showCancel = process.env.REACT_APP_SHOW_CANCEL === 'true';

  const { subscription } = useSubscriptionContext();
  const { isLoading: isLoadingCancel } = useCancelContext();
  const { getParam } = useQueryParams();

  const [isLoading, setIsLoading] = useState(true);
  const [manageType, setManageType] = useState<ManageType>(selectedType);

  const [managingProducts, setManagingProducts] = useState<GroupedContentfulProduct[][]>([]);
  const [parentProduct, setParentProduct] = useState<GroupedContentfulProduct | undefined>();

  const { mutate: mutateSubscriptions } = useGetAllSubscriptionsForCustomer();
  const { mutate: mutateIsProcessing } = useIsProcessing(subscription.stripeSubscriptionId);

  const mutate = async () => await Promise.all([mutateSubscriptions(), mutateIsProcessing()]);

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);

      const deepProducts = await DomProductRegistry.get().getDeepProductsFromIds(
        selectedDeepProductIds
      );

      const [products, subProducts] = await Promise.all([
        DomProductRegistry.get().getRecurringProductsForV2(deepProducts.flat()),
        DomProductRegistry.get().getRecurringProductsForV2(
          subscription.products.map((pfr) => pfr.product)
        ),
      ]);

      const parent = getProductToBeBundledWith(first(products.flat())!, subProducts);

      setManagingProducts(products);
      setManageType(selectedType);

      setParentProduct(parent);

      setIsLoading(false);
    };

    getProducts();
  }, [JSON.stringify(selectedDeepProductIds), selectedType]);

  const onSetClose = async () => {
    const outcome = upperCase(getParam('outcome') || '');

    if (!!outcome && ['cancel', 'retention'].includes(outcome)) {
      setIsLoading(true);

      await mutate();

      setIsLoading(false);
    }

    if (outcome === 'cancel') {
      showSuccessNotification('Your subscription has successfully been cancelled');
    } else if (outcome === 'retention') {
      showSuccessNotification('Your subscription has successfully been paused');
    }

    // reset the manage type since it displays the manage drawer a little differently
    // on initial open
    if (manageLevel === 'PRODUCT') {
      setManageType('EDIT');
    }

    onClose();
  };

  const onConfirmReschedule = async (selectedDate: Date, shippingMethodId?: number) => {
    try {
      setIsLoading(true);

      if (isToday(selectedDate)) {
        onConfirmShipNow(shippingMethodId);
      } else if (manageLevel === 'PRODUCT') {
        const startTimestamp = getUnixTime(selectedDate);

        // filter the pf ids off the sub that will be moved
        const deepProductIds = await getUnbundledDeepProductIdsFrom(
          subscription,
          managingProducts.flat().flatMap((p) => getDeepProductIdsFrom(p))
        );
        await unbundleProductFromSubscription({
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          productFrequencyIds: deepProductIds,
          type: 'RESCHEDULE',
          startTimestamp,
          shippingMethodId,
        });
      } else {
        // Getting a random time allows us to mix up the future shipments so that they don't all get
        // hit at the same exact date in the future potentially creating problems for curexa
        const millis = getMillisWithRandomTime(startOfDay(selectedDate));

        await setNextRecurrence(subscription.stripeSubscriptionId, millis, {
          shippingMethodId,
        });
      }

      await mutate();

      if (manageLevel === 'PRODUCT') {
        showSuccessNotification('Your product has successfully been rescheduled');
        navigate('/subscriptions', { replace: true });
      } else {
        showSuccessNotification('Your shipment has successfully been rescheduled');
        onClose();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onConfirmShipNow = async (shippingMethodId?: number, promotionCodeId?: string) => {
    try {
      setIsLoading(true);

      if (manageLevel === 'PRODUCT') {
        const deepProductIds = await getUnbundledDeepProductIdsFrom(
          subscription,
          managingProducts.flat().flatMap((p) => getDeepProductIdsFrom(p))
        );

        await unbundleProductFromSubscription({
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          productFrequencyIds: deepProductIds,
          type: 'SHIP_NOW',
          promotionCodeId,
          shippingMethodId,
        });
      } else {
        await billSubscriptionNow(subscription.stripeSubscriptionId, {
          shippingMethodId,
        });
      }

      await mutate();

      showSuccessNotification('Your next order has successfully been placed');

      navigate('/subscriptions', { replace: true });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <AlloyDrawer
      title={manageType !== 'CANCEL' ? capitalize(manageType.replaceAll('_', ' ')) : ''}
      drawerClass='manage-drawer'
      open={open}
      onClose={onSetClose}
      disableClosing={isLoading || isLoadingCancel}
      onBack={
        manageType === 'CANCEL' &&
        !!getParam('reason') &&
        !getParam('outcome') &&
        !(isLoading || isLoadingCancel)
          ? () => navigate(-1)
          : undefined
      }
    >
      {isLoading ? (
        <div className='drawer-body'>
          <Skeleton variant='rectangular' height={200} />
        </div>
      ) : (
        <div className='drawer-body'>
          {manageType === 'EDIT' && (
            <EditProductWrapper
              products={managingProducts}
              parentProduct={parentProduct}
              onPause={onPause}
            />
          )}

          {manageType === 'RESCHEDULE' && (
            <RescheduleWrapper
              products={managingProducts}
              onConfirm={onConfirmReschedule}
              parentProduct={parentProduct}
            />
          )}

          {manageType === 'SHIP_NOW' && (
            <ShipNowWrapper
              products={managingProducts}
              manageLevel={manageLevel}
              onConfirm={onConfirmShipNow}
              parentProduct={parentProduct}
            />
          )}

          {showCancel && manageType === 'CANCEL' && (
            <CancelContextProvider product={first(managingProducts.flat())!}>
              <CancelWrapper />
            </CancelContextProvider>
          )}
        </div>
      )}
    </AlloyDrawer>
  );
}
