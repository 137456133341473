import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect } from 'react';

const usePosthog = (key: string) => {
  const isEnabled = useFeatureFlagEnabled(key);

  useEffect(() => {
    console.log(`Retrieved result of ${key} is:`, isEnabled); // this is where to use ` since condition is inside btw!
  }, [isEnabled]);

  return { isEnabled };
};

export default usePosthog;
