import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useProductContext } from 'context/dashboard/subscriptions/product';
import { getDeepProductIdsFrom } from 'lib/shared/product';
import { useQueryParams } from 'context/shared/url/query';

export default function DifferentProductContent() {
  const { navigateWithParams } = useQueryParams();

  const { product } = useProductContext();
  const { onCancel } = useCancelContext();

  const categories = product.alloyProduct.parent.map((dp) => dp.category);
  const isMht = categories.includes('mht');

  const onChangeTreatment = () =>
    navigateWithParams('/treatment-plan', {
      manage: 'change',
      'deepProductIds[]': getDeepProductIdsFrom(product).join(','),
    });

  const onMessage = () => navigateWithParams('/messages');

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>
          {isMht
            ? 'Did you know you can easily change your treatment?'
            : 'Did you know you can change your treatment?'}
        </h4>
        <p className='content-text'>
          {isMht
            ? "If you aren't happy with your Menopause Hormone Therapy, you can easily switch your dose or form factor."
            : "If you aren't happy with your treatment and want to explore other options, your doctor is here to help! Feel free to message them to ask about your options."}
        </p>
      </div>

      <div className='btns-stack-block'>
        {isMht ? (
          <button className='primary-button' onClick={onChangeTreatment}>
            Change my treatment
          </button>
        ) : (
          <button className='primary-button' onClick={onMessage}>
            Message my doctor
          </button>
        )}

        <button className='primary-link' onClick={() => onCancel()}>
          I want to cancel
        </button>
      </div>
    </div>
  );
}
