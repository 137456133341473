"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CANCEL_QUESTIONNAIRE = void 0;
// began migration to TS so that the structure is a lil nicer
exports.CANCEL_QUESTIONNAIRE = {
    id: 9,
    title: 'MHT & Vaginal Health',
    description: '',
    version: 'v1',
    questions: [
        {
            title: 'Cancel reason',
            slug: 'cancel-reason',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: 'Cancel explanation',
            slug: 'cancel-explanation',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'Hidden',
            options: [],
            mdiCaseQuestion: true,
        },
        {
            title: "We're sorry to hear you're not feeling better. What symptoms are you still experiencing?",
            slug: 'symptoms-still-experiencing',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            options: [],
            mdiCaseQuestion: true,
            placeholder: 'Type here',
        },
        {
            title: 'How long have you been taking your medication?',
            slug: 'medication-duration',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    slug: 'medication-duration-less-6-weeks',
                    answer: 'Less than 6 weeks',
                },
                {
                    slug: 'medication-duration-between-6-1-year',
                    answer: 'Between 6 weeks - 1 year',
                },
                {
                    slug: 'medication-duration-more-1-year',
                    answer: 'More than 1 year',
                },
            ],
            mdiCaseQuestion: true,
            groupedSection: 'medication',
        },
        {
            title: 'Have you missed any doses of your medication?',
            slug: 'missed-doses-medication',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    slug: 'missed-doses-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'missed-doses-no',
                    answer: 'No',
                },
            ],
            mdiCaseQuestion: true,
            groupedSection: 'medication',
        },
        {
            title: 'Aside from your Alloy prescriptions, have you started any new medications recently?',
            slug: 'new-medications-started',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    slug: 'new-medications-started-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'new-medications-started-no',
                    answer: 'No',
                },
            ],
            mdiCaseQuestion: true,
            groupedSection: 'medication',
        },
        {
            title: 'What new medications have you started?',
            slug: 'new-medications-list',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            options: [],
            mdiCaseQuestion: true,
            orLogic: {
                'new-medications-started': ['new-medications-started-yes'],
            },
            groupedSection: 'medication',
            placeholder: 'Type here',
        },
        {
            title: 'Has anything changed in your medical history?',
            slug: 'changed-medical-history',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    slug: 'changed-medical-history-yes',
                    answer: 'Yes',
                },
                {
                    slug: 'changed-medical-history-no',
                    answer: 'No',
                },
            ],
            mdiCaseQuestion: true,
            groupedSection: 'medication',
        },
        {
            title: 'What has changed?',
            slug: 'details-changed-history',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'MultipleLine',
            options: [],
            mdiCaseQuestion: true,
            orLogic: {
                'changed-medical-history': ['changed-medical-history-yes'],
            },
            groupedSection: 'medication',
            placeholder: 'Type here',
        },
        {
            title: 'Have you tried changing your dose or form factor?',
            slug: 'changed-dose-form',
            description: '',
            isRequired: true,
            isImportant: false,
            userKey: '',
            type: 'SingleChoice',
            options: [
                {
                    slug: 'changed-dose-form-tried-dose',
                    answer: "I've tried another dose",
                },
                {
                    slug: 'changed-dose-form-tried-form',
                    answer: "I've tried another form factor",
                },
                {
                    slug: 'changed-dose-form-tried-both',
                    answer: 'I have tried both another dose and another form factor',
                },
                {
                    slug: 'changed-dose-form-not-tried',
                    answer: 'I have not tried either',
                },
            ],
            mdiCaseQuestion: true,
        },
    ],
};
