import { TextAreaField } from 'components/core/fields/TextAreaField';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { isEmpty } from 'lodash';
import { useState } from 'react';

export default function SideEffectsContent() {
  const [inputText, setInputText] = useState('');

  const { onRetentionSave, onCancel } = useCancelContext();

  return (
    <div className='cancel-content-wrapper'>
      <div className='content-block'>
        <h4 className='content-title'>We're sorry to hear that.</h4>
        <p className='content-text'>
          Our medical experts are here to work with you. What side effects are you experiencing?
        </p>
      </div>

      <form>
        <TextAreaField
          name='side-effects-field'
          value={inputText}
          onChange={setInputText}
          placeholder='Type here'
        />
      </form>

      <div className='btns-stack-block'>
        <button
          className='primary-button'
          disabled={isEmpty(inputText)}
          onClick={() => onRetentionSave({ 'cancel-explanation': inputText })}
        >
          Send to my doctor to find a better fit
        </button>

        <button
          className='primary-link'
          disabled={isEmpty(inputText)}
          onClick={() => onCancel({ 'cancel-explanation': inputText })}
        >
          I want to cancel instead
        </button>
      </div>

      <p className='content-blurb'>Note: If this is an emergency, please call 911</p>
    </div>
  );
}
