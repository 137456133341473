import { Customer } from 'client/dist/generated/alloy';
import posthog from 'posthog-js';
import { ExperienceCategory } from 'common/dist/models/experience';

export function identifyPosthog(customer: Customer) {
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    posthog.identify(customer.patientId, { email: customer.email });
  } else {
    posthog.identify(customer.patientId);
  }
}

export function posthogLogout() {
  posthog.reset();
}

export function posthogLoaded() {
  return new Promise<boolean>((resolve, _) => {
    posthog.onFeatureFlags(() => {
      resolve(true);
    });
  });
}

export function posthogCapture(event: string, categories?: ExperienceCategory[]) {
  try {
    posthog.capture(
      event,
      categories && {
        $set: {
          intake_categories: categories,
        },
      }
    );
  } catch (error) {
    console.error('Error capturing PH Event: ', error);
  }
}
