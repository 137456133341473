import posthog from 'posthog-js';

//  "KnownPreferences" CRO Test | testing allowing the user to skip the "add-on preferences" step of the checkout flow if
//  they do not already know what they want
export const showKnownPreferencesPage = () => {
  const knownPreferencesStatus = posthog.getFeatureFlag('known-preferences');
  console.log('Retrieved result of known-preferences is:', knownPreferencesStatus);

  return knownPreferencesStatus === 'test';
};

// "Reviews In Checkout" CRO Test | testing a positive customer review included during user's their checkout flow
// that is relevant to their selected product(s)
export const showCheckoutReview = () => {
  const checkoutReviewStatus = posthog.getFeatureFlag('checkout-review');
  console.log('Retrieved result of checkout-review is:', checkoutReviewStatus);

  return checkoutReviewStatus === 'test';
};
