import GroupedContentfulProduct from 'common/dist/products/groupedContentfulProduct';

import alertIcon from 'assets/svg/core/icons/alert-info-icon.svg';

import ProductListBlock from '../content/ProductListBlock';

import { getProductNameWithDosage } from 'lib/shared/product/name';
import { useQueryParams } from 'context/shared/url/query';
import { lowerCase } from 'lodash';
import { ManageType } from 'models/components/shared/manage-type';

interface Props {
  products: GroupedContentfulProduct[][];
  parentProduct: GroupedContentfulProduct | undefined;
  onPause: () => void;
}

export default function EditProductWrapper({ products, parentProduct, onPause }: Props) {
  const { setParam } = useQueryParams();

  const showCancel = process.env.REACT_APP_SHOW_CANCEL === 'true';

  const onSelect = (manageType: ManageType) => {
    setParam('manage', lowerCase(manageType).replaceAll('_', '-'));
  };

  return (
    <div className='edit-product-wrapper'>
      <ProductListBlock products={products} />

      {!!parentProduct && (
        <div className='product-discount-block'>
          <p className='discount-title'>
            <img src={alertIcon} alt='alert info icon' className='title-icon' />
            Don't lose your discount
          </p>

          <p className='discount-text'>
            This treatment is eligible for a discount when shipped together with{' '}
            {getProductNameWithDosage(parentProduct)[0].cleanName}. If shipped separately, you'll be
            charged the full price.
          </p>
        </div>
      )}

      <div className='product-btns-block'>
        <button className='secondary-border-button' onClick={() => onSelect('RESCHEDULE')}>
          Reschedule
        </button>

        <button className='primary-button' onClick={() => onSelect('SHIP_NOW')}>
          Ship now
        </button>
      </div>

      {/* MARK: the flag here checks whether to display cancel or not, once we are good on cancel we will
remove the deprecated pause code. */}
      <button
        className='primary-link'
        onClick={() => (showCancel ? onSelect('CANCEL') : onPause())}
      >
        {showCancel ? 'Cancel subscription' : 'Pause this treatment'}
      </button>
    </div>
  );
}
