import { useGetAllSubscriptionsForCustomer } from 'client/dist/generated/alloy';
import CheckmarkListBlock from 'components/shared/CheckmarkList';
import { useCancelContext } from 'context/dashboard/subscriptions/cancel';
import { useProductContext } from 'context/dashboard/subscriptions/product';
import { AllBenefits } from 'data/dashboard/cancel/all-benefits';
import { isLastGroupedProductIn } from 'lib/shared/subscriptions/product';
import { getSubscriptionsWithStatus } from 'lib/shared/subscriptions/status';
import { useQueryParams } from 'context/shared/url/query';
import DoctorCardBlock from './Doctor';

export default function NoSubscriptionContent() {
  const { setParam } = useQueryParams();
  const { onCancel } = useCancelContext();
  const { product } = useProductContext();

  const { data: subscriptions = [] } = useGetAllSubscriptionsForCustomer();
  const { activeSubs } = getSubscriptionsWithStatus(subscriptions);

  const isLastProduct = isLastGroupedProductIn(activeSubs, product);

  const onKeepSubscription = () => setParam('outcome', 'keep');

  const onReschedule = () => setParam('manage', 'reschedule');

  if (isLastProduct) {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Alloy is more than just a subscription.</h4>
        </div>

        <DoctorCardBlock showAbout />
        <CheckmarkListBlock list={AllBenefits} />

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onKeepSubscription}>
            Stay with alloy
          </button>

          <button className='primary-link' onClick={() => onCancel()}>
            Continue to cancel
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className='cancel-content-wrapper'>
        <div className='content-block'>
          <h4 className='content-title'>Before you go, maybe we can help!</h4>
          <p className='content-text'>
            With Alloy, you can easily reschedule your treatments and have them ship on a schedule
            that works for you. Would you like to reschedule instead?
          </p>
        </div>

        <div className='btns-stack-block'>
          <button className='primary-button' onClick={onReschedule}>
            Yes, reschedule
          </button>

          <button className='primary-link' onClick={() => onCancel()}>
            No, I want to cancel
          </button>
        </div>
      </div>
    );
  }
}
